// init
import axios from 'axios'
import services from '../data.services/data-services-main.json'
import { encrypt, decrypt } from "./srvc-encr-node";

const base = services.data.find(x => x.code === 'users.creators').link


export const UserAccountCreate = async item => {
  const basx = base + '/users/create'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data }  
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const UserAccountDetails = async item => {
  const basx = base + '/users/details'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const UserAccountList = async item => {
  const basx = base + '/users/accounts'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data }
    // return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const UserOnboardStatus = async item => {
  const basx = base + '/user/onboard/status'
  const head = {
    'Content-Type': 'application/json',
    "Authorization": item.client
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,item.secret)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TeamMemberCreate = async item => {
  const basx = base + '/teams/member/create'
  const head = {
    'Content-Type': 'application/json',
    "Authorization": item.client
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,item.secret)


  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TeamSocialUpdate = async (item) => {
  const basx = base + "/teams/social/update";
  const head = {
    "Content-Type": "application/json",
    Authorization: item.client,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };
  const payload = await encrypt(datx, item.secret);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(result.data.data, item.secret);
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const TeamMemberDelete = async item => {
  const basx = base + '/teams/member/delete'
  const head = {
    'Content-Type': 'application/json',
    "Authorization": item.client
  }
  const datx = { data: item.data, srvc:process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,item.secret)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TeamMemberList = async item => {
  const basx = base + '/teams/member/list'
  const head = {
    'Content-Type': 'application/json',
    "Authorization": item.client
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,item.secret)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data } 
   } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TeamsContactsGraphStatistics = async (item) => {
    
  const basx = base + '/teams/contact/dashboard/summary';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":item.client
  }
  const datx = {data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC}
  const payload = await encrypt(datx,item.secret)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TeamContactCreate = async item => {
  const basx = base + '/teams/contact/create'
  const head = {
    'Content-Type': 'application/json',
    "Authorization": item.client
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,item.secret)

  var result
  try {
    result = await axios.post(basx,{payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TeamContactDelete = async item => {
  const basx = base + '/teams/contact/delete'
  const head = {
    'Content-Type': 'application/json',
    "Authorization": item.client
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,item.secret)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TeamContactList = async item => {
  const basx = base + '/teams/contact/list'
  const head = {
    'Content-Type': 'application/json',
    "Authorization": item.client
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,item.secret)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TeamContactSearchList = async item => {
  const basx = base + '/teams/contact/search'
  const head = {
    'Content-Type': 'application/json',
    "Authorization": item.client
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,item.secret)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }  
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TeamContactCount = async item => {
  const basx = base + '/teams/contact/count'
  const head = {
    'Content-Type': 'application/json',
    "Authorization": item.client
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,item.secret)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data } 
   } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TeamContactDetails = async item => {
  const basx = base + '/teams/contact/details'
  const head = {
    'Content-Type': 'application/json',
    "Authorization": item.client
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,item.secret)
  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data } 
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TeamAccountDetails = async (item) => {
  const basx = base + "/teams/details";
  const head = {
    "Content-Type": "application/json",
    Authorization: item.client,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };
  const payload = await encrypt(datx, item.secret);
  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(result.data.data, item.secret);
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const AddBulkContacts = async (item) => {
  
  const basx = base + '/teams/contact/create/bulk';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item.client
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,item.secret)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, memo: result.data.memo ,data }  
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
} 