// network
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { GetUserForm } from "../../services/srvc-utilities";
import {
  GetLocalUser,
  GetLocalBusiness,
  GetAuthUser,
} from "../../services/srvc-auth-local";

import {
  TeamAccountDetails,
  TeamMemberDelete,
  TeamMemberList,
} from "../../services/srvc-users-creators-realm";

export default function SociaListModule(props) {
  // console.log(props.search)
  const auth = GetAuthUser();

  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState({});

  const [refresh, setRefresh] = useState(true);

  const platformIcons = {
    facebook: "bx bxl-facebook-circle",
    instagram: "bx bxl-instagram-alt",
    twitter: "bx bxl-twitter",
    linkedin: "bx bxl-linkedin-square",
    organizationId: "bx bxs-business",
  };

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);
        const result = await TeamAccountDetails({
          data: {
            team: asset.item,
          },
          service: asset?.creds?.service,
          client: asset?.creds?.client,
          secret: asset?.creds?.secret,
        });

        if (result.stat) {
          console.log("Social", result.data.social);
          setData(result.data.social);
        }
        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, [props.search, refresh]);

  if (loader)
    return (
      <>
        <div
          className="p-3 back-color-wite rounded-xd border align-middle"
          style={{}}
        >
          <span className="align-middle text-lead">
            <i className="bx bxs-info-circle text-color-wait"></i>
          </span>
          <span className="ms-1 text-color-tone">Please Wait...</span>
        </div>
      </>
    );

  if (!loader && data.length == 0)

    return (
      <>
        <div
          className="p-3 back-color-wite rounded-xd border align-middle"
          style={{}}
        >
          <span className="align-middle text-lead">
            <i className="bx bxs-info-circle text-color-tint"></i>
          </span>
          <span className="ms-1 text-color-tone">No Social Details</span>
        </div>
      </>
    );

  return (
    <>
      <div className="back-color-wite rounded border">
        {/* header */}
        <div className="border-bottom text-bold">
          <div className="d-flex p-2 px-3">
            <div className="" style={{ width: "40%" }}>
              <p className="m-0 text-sm">
                <span className="">{"Social Name"}</span>
              </p>
            </div>

            <div className="d-none d-md-block ms-2 ">
              <p className="m-0 text-sm">
                <span className="">{"Links"}</span>
              </p>
            </div>

            <div className="ms-auto text-end text-sm d-none">
              <p className="m-0 text-sm">
                <span className="">{"Link"}</span>
              </p>
            </div>
          </div>
        </div>

        {/* data */}
        {data &&
          data.map((item, index) => {

            return (
              <div key={index}>
                <div className="d-flex p-2 px-3">
                  <div
                    className="d-flex align-items-center"
                    style={{ width: "50%" }}
                  >
                    <div className="me-2">
                      <span className="align-middle">
                        <i
                          className={platformIcons[item.name] || "bx bxl-globe"}
                          style={{ fontSize: "1.5rem" }}
                        ></i>
                      </span>
                    </div>
                    <div>
                      <p className="m-0 text-sm">
                        <span className="text-bold">
                          {item.name.charAt(0).toUpperCase() +
                            item.name.slice(1)}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="d-none d-md-block ms-2">
                    <p className="mt-1 m-0 text-sm">
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.link}
                      </a>
                    </p>
                  </div>
                </div>
                <div className="border-bottom"></div>
              </div>
            );
          })}
      </div>
    </>
  );
}
