import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import NavsButtonBack from "../webx/navs-button-back";
import NavsButtonNext from "../webx/navs-button-next";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import {
  AssetsCreatorOffersList,
  AssetsIndividualOfferCancel,
  TransfersAssetListUnit,
} from "../../services/srvc-transfers-assets-realm";

import listStatus from "../../data.static/data-transfers-status.json";
import { AssetsDataTransfers } from "../../services/srvc-credentials-realm";

export default function UnitTransfersListModule(props) {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();
  const { id } = useParams();

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]); // Consolidated full data
  const [paginatedData, setPaginatedData] = useState([]); // Data for the current page
  const [list, setList] = useState(listStatus.data);

  const [index, setIndex] = useState(1); // Current page index
  const [items, setItems] = useState(10); // Items per page

  const [total, setTotal] = useState(0); // Total count
  const [refresh, setRefresh] = useState(false);

  const [text, setText] = useState("");

  const fetchPaginatedData = async (fetchFunc, dataParams) => {
    let resultData = [];
    let pageIndex = 1;
    let fetched = [];

    do {
      dataParams.index = pageIndex.toString();
      const result = await fetchFunc({
        data: dataParams,
        service: asset?.creds?.service,
        client: asset?.creds?.client,
        secret: asset?.creds?.secret,
      });

      fetched = result?.data?.list || [];
      resultData = [...resultData, ...fetched];
      pageIndex++;
    } while (fetched.length > 0); // Continue until no more data

    return resultData;
  };

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        const datx = {
          unit: id,
          user: asset.item,
          index: "1",
          items: "10", // Start with small chunks
        };

        const [resultData, offersData, transfersData] = await Promise.all([
          fetchPaginatedData(TransfersAssetListUnit, datx),
          fetchPaginatedData(AssetsCreatorOffersList, datx),
          fetchPaginatedData(AssetsDataTransfers, datx),
        ]);

        const fullList = [...offersData, ...resultData, ...transfersData];
        setData(fullList); // Store full list of data
        setTotal(fullList.length); // Total item count

        const slicedData = fullList.slice(0, items); // First page
        setPaginatedData(slicedData);

        setText(
          `1 - ${items < fullList.length ? items : fullList.length} of ${
            fullList.length
          }`
        );

        setLoader(false);
      };
      fetchData();
    }
  }, [refresh, id, items ]);

  useEffect(() => {
    // Update paginated data whenever `index` changes
    const startIndex = (index - 1) * items;
    const endIndex = index * items;
    const slicedData = data.slice(startIndex, endIndex);

    setPaginatedData(slicedData);

    setText(
      `${startIndex + 1} - ${endIndex < total ? endIndex : total} of ${total}`
    );
  }, [index, data, items, total]);

  const handleCancel = (item) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to cancel this offer?"
    );
    if (isConfirmed) {
      handleOfferCancel(item);
    }
  };

  const handleOfferCancel = async (item) => {
    const res = await AssetsIndividualOfferCancel({
      data: { item: item },
      service: asset?.creds?.service,
      client: asset?.creds?.client,
      secret: asset?.creds?.secret,
    });
    if (res.stat) {
      setRefresh(!refresh);
    }
  };

  const handleViewClick = (item, mode) => {
    if (mode === "data") {
      navigate(`/team/tx/data/${item}`);
    } else navigate(`/team/tx/assets/${item}`);
  };

  const NextIndex = () => {
    if (index * items < total) {
      setIndex((prev) => prev + 1);
    }
  };

  const LastIndex = () => {
    if (index > 1) {
      setIndex((prev) => prev - 1);
    }
  };

  if (!loader && (!paginatedData || paginatedData.length === 0))
    return (
      <div className="p-3 back-color-wite rounded border align-middle">
        <span className="align-middle text-lead">
          <i className="bx bxs-info-circle text-color-tint"></i>
        </span>
        <span className="ms-1 text-color-tone">No Transfers</span>
      </div>
    );

  return (
    <>
      <div className="back-color-wite border rounded">
        {/* Header */}
        <div className="border-bottom text-small text-bold">
          <div className="d-flex p-2 px-3">
            <div style={{ width: "5%" }}>
              <p className="m-0">
                <span className="text-small">
                  <i className="bx bxs-grid-alt text-color-tint"></i>
                </span>
              </p>
            </div>
            <div style={{ minWidth: "51%" }}>
              <p className="m-0">User</p>
            </div>
            <div style={{ width: "25%" }}>
              <p className="m-0 text-sm">Status</p>
            </div>
            <div className="ms-auto text-end text-sm" style={{ width: "20%" }}>
              <p className="m-0 text-sm">Action</p>
            </div>
          </div>
        </div>

        {/* Data */}
        {paginatedData.map((item, i) => (
          <div key={i}>
            <div className="d-flex p-2 px-3">
              <div style={{ width: "55%" }}>
                <p className="m-0">
                  <span className="text-bold">{item.user.name}</span>
                </p>
                <p className="m-0 text-small">
                  <span>
                    {new Date(parseInt(item?.created)).toLocaleString() ||
                      "******"}
                  </span>
                </p>
              </div>
              <div style={{ width: "25%" }}>
                <span
                  className={`p-1 rounded text-mini text-uppercase text-color-wite back-${
                    list?.find(
                      (x) => x.code === item.status || x.code === item.mode
                    ).color
                  }`}
                >
                  {
                    list?.find(
                      (x) => x.code === item.status || x.code === item.mode
                    ).memo
                  }
                </span>
              </div>
              <div className="ms-auto text-end" style={{ width: "20%" }}>
                {item.mode === "offer" ? (
                  <p
                    className="m-0 cursor"
                    onClick={() => handleCancel(item.item)}
                  >
                    <span className="ms-1 cursor bg-body-secondary rounded p-1">
                      Cancel
                    </span>
                  </p>
                ) : (
                  <p
                    className="m-0 cursor"
                    onClick={() => handleViewClick(item.item, item.mode)}
                  >
                    <span className="ms-1 cursor bg-body-secondary rounded p-1">
                      View
                    </span>
                  </p>
                )}
              </div>
            </div>
            <div
              className={i < paginatedData.length - 1 ? "border-bottom" : ""}
            ></div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      <WebbDividerSmall />
      <div>
        <div className="d-flex justify-content-between">
          <div className={index === 1 ? "disabled" : ""} onClick={LastIndex}>
            <NavsButtonBack />
          </div>
          <div>
            <p className="mt-2 text-small">{text}</p>
          </div>
          <div
            className={index * items >= total ? "disabled" : ""}
            onClick={NextIndex}
          >
            <NavsButtonNext />
          </div>
        </div>
      </div>
    </>
  );
}
