import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WebbDividerSmall from "../webx/webb-divider-sm";

import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";

import { GetUserForm } from "../../services/srvc-utilities";
import {
  AssetListCreator,
  AssetSearch,
} from "../../services/srvc-assets-realm";
import { DispalyLoder } from "../common/utils";

import { useDispatch, useSelector } from "react-redux";

const listFormat = require("../../data.static/data-asset-formats.json").data;

export default function AssetsAwardsListCreatorModule() {
  const dispatch = useDispatch();
  const assetSearchData = useSelector((state) => state.asset);
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState();
  const [filterData, setFilterData] = useState([]);
  const [sort, setSort] = useState();
  const [taxxonArray, setTaxxonArray] = useState([]);
  const [callFunction, setCallFunction] = useState(true);
  const [trueCategories, setTrueCategories] = useState([]);

  const categoriesToCheck = [
    "academics",
    "work",
    "events",
    "membership",
    "awards",
    "social",
    "all",
  ];

//   useEffect(() => {
//     const trueCategories = categoriesToCheck.filter(
//       (category) => assetSearchData[category]
//     );
//     setTrueCategories(trueCategories);
//   }, [assetSearchData]);

  function filterTaxxonIdsByCategory(listFormat, trueCategories) {
    const categoryCodeMapping = {
      work: "7",
      membership: "31",
      awards: "34",
      academics: "4",
      events: "32",
    };
    let filteredTaxxonIds = [];
    trueCategories.forEach((category) => {
      const categoryCode = categoryCodeMapping[category];
      if (categoryCode) {
        const filteredIds = listFormat
          .filter((item) => item.taxxon.startsWith(categoryCode))
          .map((item) => item.taxxon);
        filteredTaxxonIds = filteredTaxxonIds.concat(filteredIds);
      }
    });

    setTaxxonArray(filteredTaxxonIds);
    setCallFunction(!callFunction);
  }

  useEffect(() => {
    filterTaxxonIdsByCategory(listFormat, trueCategories);
  }, [trueCategories]);

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);
        const datx = {
          creator: asset.item,
          index: "1".toString(),
          items: "25".toString(),
          filters: { fields: taxxonArray || [] },
        };
        const result = await AssetListCreator({
          data: datx,
          service: asset?.creds?.service,
          client: asset?.creds?.client,
          secret: asset?.creds?.secret,
          type: "social"
        });
        if (result.stat) {
          setData(result?.data?.list);
          setFilterData(result?.data?.list);
          const sortx = Array.from(result.data.list, (x) => {
            return {
              name: listFormat.find((z) => z.taxxon == x.webx.taxxon).form,
              taxx: x.webx.taxxon,
            };
          });
          const sortz = Array.from(new Set(sortx.map(JSON.stringify))).map(
            JSON.parse
          );
          setSort(sortz);
        }

        setLoader(false);
      };
      if (assetSearchData.search == "") {
        fetchData();
      }
    } else {
    }
  }, [callFunction]);

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        const datx = {
          creator: asset.item,
          index: 1,
          items: 25,
          search: assetSearchData?.search,
          filters: { fields: taxxonArray || [] },
        };
        const result = await AssetSearch({
          data: datx,
          service: asset?.creds?.service,
          client: asset?.creds?.client,
          secret: asset?.creds?.secret,
        });
        if (result.stat) {
          // console.log(result.data.list);
          setData(result?.data?.list);
          setFilterData(result?.data?.list);
          const sortx = Array.from(result.data.list, (x) => {
            return {
              name: listFormat.find((z) => z.taxxon == x.webx.taxxon).form,
              taxx: x.webx.taxxon,
            };
          });
          // console.log(sortx);
          const sortz = Array.from(new Set(sortx.map(JSON.stringify))).map(
            JSON.parse
          );
          // console.log(sortz);
          setSort(sortz);
        }

        setLoader(false);
      };
      if (assetSearchData.search != "") {
        fetchData();
      }
    } else {
    }
  }, [assetSearchData?.searchButton]);

  const handleClick = async (item) => {
    var unit = data.find((x) => x?.item == item)?.units?.list[0];
    if (unit) {
     navigate(`/${asset.role}/units/social/${unit?.item}`);
    } else {
      alert("Please wait , processing...");
    }
    setCallFunction(!callFunction);
  };

  if (loader) return <DispalyLoder />;
  if (!loader && (!data || data.length === 0))
    return <DispalyLoder msg={"No Assets Listed"} />;

  return (
    <>
      {/* data */}
      <div className="d-none d-md-block">
        {sort &&
          sort.map((sortz, z) => (
            <div className="mb-3" key={z}>
              <h2 className="text-normal text-color-next mb-2">{sortz.name}</h2>
              {/* <p className="text-bold m-0 ms-3" key={z}>{sortz.name}</p> */}

              <div className="back-color-wite rounded-xd">
                <WebbDividerSmall />
                <div className="row row-cols-1 row-cols-md-2 g-3">
                  {filterData &&
                    filterData.map((item, x) =>
                      item.item && item.webx.taxxon == sortz.taxx ? (
                        <div className="col m-0 mb-3" key={x}>
                          <div
                            className="card border rounded h-100 cursor"
                            onClick={() => handleClick(item?.item)}
                          >
                            <div className="media-standard rounded-top">
                              <img
                                src={item?.media?.link} // Route of the image file
                                layout="fill"
                                className="img-fluid w-100 rounded-none shadow rounded-bottom-none"
                                alt="..."
                              />
                              <div className="d-none btn back-color-main rounded-xx text-mini">
                                {item?.webx?.number || "******"}
                              </div>
                            </div>

                            <div className="p-3">
                              <p className="text-bold text-color-next text-sm mb-1">
                                {item?.meta?.name}
                              </p>
                              <p className="text-small text-md m-0">
                                {item?.meta?.memo}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    )}
                </div>
              </div>

              <WebbDividerSmall />
            </div>
          ))}
      </div>

      <div className="d-md-none">
        {sort &&
          sort.map((sortz, z) => (
            <div className="mb-3" key={z}>
              <p className="text-bold m-0 ms-3" key={z}>
                {sortz.name}
              </p>
              <WebbDividerSmall />

              <div className="back-color-wite rounded-xd p-3 ">
                {data &&
                  data.map((item, x) =>
                    item.feature.format == sortz.name ? (
                      <div className="" key={x}>
                        <div
                          className="d-flex justify-content-start"
                          onClick={() => handleClick(item.item)}
                        >
                          <div className="">
                            <div
                              className="media-cube rounded-xd"
                              style={{ width: "60px", height: "60px" }}
                            >
                              <img
                                src={item.media.link} // Route of the image file
                                layout="fill"
                                className="img-fluid"
                                alt={item?.meta?.name || "******"}
                              />
                            </div>
                          </div>
                          <div className="ms-2 w-100">
                            <p className="text-normal text-bold m-0">
                              {item?.meta?.name || "******"}
                            </p>
                            <p className="text text-sm m-0">
                              {item?.meta?.memo || "******"}
                            </p>
                            <p className="text-small text-sm m-0">
                              {item?.webx?.number || "******"}
                            </p>
                            <div className="">
                              <hr className="m-0 mt-2 mb-2"></hr>
                            </div>
                          </div>
                          <div className="ms-auto"></div>
                        </div>

                        <div
                          className={
                            x < data.length - 1
                              ? "d-none mt-2 mb-2 border-bottom"
                              : ""
                          }
                        ></div>
                      </div>
                    ) : (
                      ""
                    )
                  )}
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
