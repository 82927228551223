// assets
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoaderMedium from "../webx/webb-loader-md";
import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbModuleInfo from "../webx/webb-module-info";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import FileCreateCX from "../../services/srvc-filecreate-cweb-xx";

import { AssetUnitListMembers } from "../../services/srvc-assets-units-realm";
import {
  AssetCreate,
  AssetCreateAcademics,
} from "../../services/srvc-assets-realm";

const listFormat = require("../../data.static/data-asset-formats.json").data;

const member0 = {
  meta: { name: "General", memo: "General Audience" },
  rate: { nmbr: "", tick: "inr" },
  nmbr: "3356199999990456",
  taxx: "69c214c90838489b9ecb35383bebe94e6",
  user: "69c214c90838489b9ecb35383bebe94e6",
  actv: true,
  item: "69c214c90838489b9ecb35383bebe94e6",
};

export default function AssetsCreateAcademicsModule() {
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];

  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
  // console.log(asset)

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);

  const [form, setForm] = useState(false);
  const [memo, setMemo] = useState("");

  const [format, setFormat] = useState();
  const [sort, setSort] = useState();
  const [term, setTerm] = useState([12]);

  const [emilliseconds, setEmilliseconds] = useState("");
  const [smilliseconds, setSmilliseconds] = useState("");

  const [rateMembers, setRateMembers] = useState([]);
  const [rateEvents, setRateEvents] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState('51');
  const [media, setMedia] = useState({ link: "******", mime: "******" });
  const [data, setData] = useState({
    name: "",
    memo: "",
    format: lastSegment,
    sort: "",
    taxx: "",
    item: "",
    start: new Date().toISOString().substring(0, 10),
    end: new Date().toISOString().substring(0, 10),
    validFrom: "",
    validTo: "",
    term: "12",
    rate: "",
  });

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        const datx = { creator: asset.item };
        const result = await AssetUnitListMembers({
          data: datx,
          service: asset?.creds?.service,
          client: asset?.creds?.client,
          secret: asset?.creds?.secret,
        });

        if (result.stat) {
          var memberx = Array.from(result.data.list, (x) => {
            return {
              meta: x.meta,
              rate: { nmbr: "", tick: "inr" },
              nmbr: x.number,
              taxx: x.taxxon,
              user: x.user,
              actv: false,
              item: x.item,
            };
          });
          setRateMembers([member0]);
          setRateEvents([member0, ...memberx]);
        }
        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, []);

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);
        var activelist = listFormat.filter((x) => x.active);
        var formatx = [...new Set(activelist.map((x) => x.form))];
        setFormat(
          Array.from(formatx, (x) => {
            return { name: x };
          })
        );
        //Array.from(listFormat, x => { return {name: x.form}})

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, []);

  useEffect(() => {
    setSort(null);
    handleChange("item", "");
    if (data.format && data.format !== "") {
      const sortx = listFormat.filter((x) => x.form == data.format && x.active);
      setSort(
        Array.from(sortx, (x) => {
          return { name: x.name, taxx: x.taxx, item: x.item };
        })
      );
    } else {
    }
  }, [data.format]);

  useEffect(() => {
    setForm(false);
    if (data.name !== "" && data.memo !== "" && selectedNetwork && media.link !== "******")
      setForm(true);
  }, [data, media]);

  useEffect(() => {
    const sdateValue = data.start;
    const stimeValue = data.startTime ? data.startTime + ":00" : "00:00:00";

    const [syear, smonth, sday] = sdateValue.split("-");
    const [shours, sminutes, sseconds] = stimeValue.split(":");

    const scombinedDateTime = new Date(
      syear,
      smonth - 1,
      sday,
      shours,
      sminutes,
      sseconds
    );

    setSmilliseconds(scombinedDateTime.getTime());

    const edateValue = data.end;
    const etimeValue = data.endTime ? data.endTime + ":00" : "12:00:00";

    const [eyear, emonth, eday] = edateValue.split("-");
    const [ehours, eminutes, eseconds] = etimeValue.split(":");

    const ecombinedDateTime = new Date(
      eyear,
      emonth - 1,
      eday,
      ehours,
      eminutes,
      eseconds
    );

    setEmilliseconds(ecombinedDateTime.getTime());
  }, [data.start, data.end, data.validFrom, data.ValidTo]);

  const handleSubmit = async () => {
    setLoader(true);
    setSubmit(true);
    setMemo("Please wait...");
    const datx = {
      name: data.name,
      memo: data.memo,
      media: media,
      chain: selectedNetwork,
      date: {
        start: smilliseconds.toString(),
        end: emilliseconds.toString(),
        validFrom: data.validFrom,
        validTo: data.validTo,
      },
      feature: { format: data.format, sort: data.sort, role: "user" },
      taxxon: listFormat.find((x) => x.item == data.item).taxxon,
      units: { number: "", ticker: "" },
      rates: data.format == "events" ? rateEvents : rateMembers,
      creator: asset.item,
    };
    // console.log (datx)

    const result = await AssetCreateAcademics({
      data: datx,
      service: asset?.creds?.service,
      client: asset?.creds?.client,
      secret: asset?.creds?.secret,
    });

    if (result.stat) {
      setDone(true);
      setMemo("Asset Created");
      navigate("/team/assets");
    } else {
      setDone(false);
      setSubmit(false);
      setMemo("Asset Creation Failed");
    }

    setLoader(false);
  };

  const handleMediaBanner = async (filedata) => {
    // console.log (filedata);
    var mediax = { link: filedata?.file.link, mime: filedata?.file?.mime };
    setMedia((media) => mediax);
  };

  const handleChange = async (x, item) => {
    setData({ ...data, [x]: item });
  };

  const handleRatesMembers = async (x, item) => {
    // console.log (x, item)
    var ratex = rateMembers;
    ratex[x] = {
      ...ratex[x],
      rate: { ...ratex[x].rate, nmbr: item.toString() },
    };
    // console.log (ratex)
    setRateMembers((rateMembers) => [...ratex]);
  };

  const handleRatesEvents = async (x, item) => {
    // console.log (x, item)
    var ratex = rateEvents;
    ratex[x] = {
      ...ratex[x],
      rate: { ...ratex[x].rate, nmbr: item.toString() },
    };
    // console.log (ratex)
    setRateEvents((rateEvents) => [...ratex]);
  };

  // if (loader) return <><div className="text-center"><WebbLoaderSmall/></div></>

  return (
    <>
      {/* info */}
      <div className="mx-3">
        <h2 className="text-normal m-0">{"Create Asset"}</h2>

        <WebbDividerSmall />
      </div>

      <div
        className={
          submit
            ? "back-color-wite p-3 rounded-xd"
            : "back-color-wite p-3 rounded-xd"
        }
      >
        <div className={""}>
          <p className="text-normal m-0">1. Select Format</p>
          <WebbDividerSmall />

          <div className="mb-3">
            <label className="form-label text-small mb-2">
              Select Asset Format <FormNeeded />
            </label>
            <div className="" style={{ display: "flex", flexWrap: "wrap" }}>
              {format &&
                format.map((item, i) => (
                  <span
                    className={`p-2 px-3 rounded-wd me-1 cursor  mb-1
                ${
                  data.format == item.name
                    ? "back-color-main text-color-wite"
                    : "back-color-lite"
                }`}
                    key={i}
                    // onClick={() => handleChange('format', item.name)}
                    onClick={() => navigate(`/team/assets/create/${item.name}`)}
                  >
                    {item.name}
                  </span>
                ))}
            </div>

            <WebbDividerMedium />
            <div className={data.format == "" ? "d-none" : ""}>
              <label className="form-label text-small mb-2">
                Select Credential <FormNeeded />
              </label>
              <div className="" style={{ display: "flex", flexWrap: "wrap" }}>
                {sort &&
                  sort.map((item, i) => (
                    <span
                      className={`p-2 px-3 rounded-wd me-1 cursor mb-1
                ${
                  data.item == item.item
                    ? "back-color-next text-color-wite"
                    : "border"
                }`}
                      key={i}
                      onClick={() => handleChange("item", item.item)}
                    >
                      {item.name}
                    </span>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <WebbDividerMedium />
        <div className={data.item == "" ? "d-none" : ""}>
          <p className="text-normal m-0">2. Enter Details</p>
          <WebbDividerSmall />

          <div className="mb-3">
            <label className="form-label text-small">
              Name <FormNeeded />
            </label>
            <input
              type="text"
              className="form-control height-md"
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              value={data.name}
              onChange={({ target }) => {
                handleChange("name", target.value);
              }}
              disabled={loader || submit}
              maxLength={32}
              placeholder=""
            ></input>
          </div>

          <div className="mb-3">
            <label className="form-label text-small">
              Details <FormNeeded />
            </label>
            <textarea
              className="form-control"
              rows="3"
              style={{ fontSize: "0.9rem" }}
              value={data.memo}
              onChange={({ target }) => {
                handleChange("memo", target.value);
              }}
              disabled={loader || submit}
            ></textarea>
          </div>

          <div className="mb-3">
            <label className="form-label text-small">
              Add Media <FormNeeded />
            </label>
            <FileCreateCX size="media-standard" media={handleMediaBanner} />
          </div>

          <WebbDividerSmall />
          <div
            className={`d-flex mb-3 gap-2 ${
              data.format == "academics" ? "" : "d-none"
            }`}
          >
            <div className="w-50">
              <label className="form-label text-small">
                Start Date <FormNeeded />
              </label>
              <input
                type="date"
                className="form-control height-md"
                style={{ fontSize: "0.9rem", height: "2.7rem" }}
                value={data.start}
                onChange={({ target }) => {
                  handleChange("start", target.value);
                }}
                disabled={loader || submit}
                placeholder=""
              ></input>
            </div>
            <div className="w-50">
              <label className="form-label text-small">
                End Date <FormNeeded />
              </label>
              <input
                type="date"
                className="form-control height-md"
                style={{ fontSize: "0.9rem", height: "2.7rem" }}
                value={data.end}
                onChange={({ target }) => {
                  handleChange("end", target.value);
                }}
                disabled={loader || submit}
                placeholder=""
              ></input>
            </div>
          </div>

          <div
            className={`d-flex mb-3 gap-2 ${
              data.format == "academics" ? "" : "d-none"
            }`}
          >
            <div className="w-50">
              <label className="form-label text-small">
                Certificate Validty (From) <FormNeeded />
              </label>
              <input
                type="date"
                className="form-control height-md"
                style={{ fontSize: "0.9rem", height: "2.7rem" }}
                value={data.validFrom}
                onChange={({ target }) => {
                  handleChange("validFrom", target.value);
                }}
                disabled={loader || submit}
                placeholder=""
              ></input>
            </div>
            <div className="w-50">
              <label className="form-label text-small">
                Certificate Validity (To) <FormNeeded />
              </label>
              <input
                type="date"
                className="form-control height-md"
                style={{ fontSize: "0.9rem", height: "2.7rem" }}
                value={data.validTo}
                onChange={({ target }) => {
                  handleChange("validTo", target.value);
                }}
                disabled={loader || submit}
                placeholder=""
              ></input>
            </div>
          </div>

          <div
            className={`mb-3 ${
              data.item == "17a2d2b85f5d4a1196fbcbcb67ace8bf1" ? "" : "d-none"
            }`}
          >
            <label className="form-label text-small">
              Select Term (Months) <FormNeeded />
            </label>
            <div className="mt-2 mb-2">
              {term &&
                term.map((item, i) => (
                  <span
                    className={`p-2 px-3 rounded-wd me-1 cursor 
                ${
                  data.term == item
                    ? "back-color-main text-color-wite"
                    : "back-color-lite"
                }`}
                    key={i}
                    onClick={() => handleChange("term", item)}
                  >
                    {item}
                  </span>
                ))}
            </div>
          </div>

          <WebbDividerMedium />
          <div className="">
          <label className="form-label text-small">
            Blockchain Network <FormNeeded />
          </label>
          <div className="d-flex justify-content-start align-items-center ">
            <div className="d-none">
              <input
                type="radio"
                name="blockchain"
                value="416001"
                onChange={({ target }) => setSelectedNetwork(target.value)}
                disabled={loader || submit}
              />
              <label className="mx-1">Algorand</label>
            </div>

            <div className="m-3">
              <input
                type="radio"
                name="blockchain"
                value="51"
                onChange={({ target }) => setSelectedNetwork(target.value)}
                disabled={loader || submit}
              />
              <label className="mx-1">XDC Network</label>
            </div>
          </div>
        </div>
        </div>

        <WebbDividerMedium />
        <div
          className={
            data.item == "b027a554882e44fcb5ff64cd6369f3409" ? "" : "d-none"
          }
        >
          <p className="text-normal m-0">3. Rates</p>

          <WebbDividerSmall />
          <div className={`d-flex mb-1 gap-2`}>
            <div className="w-50">
              <label className="form-label text-small">
                Audience <FormNeeded />
              </label>
            </div>
            <div className="w-50">
              <label className="form-label text-small">
                Rate <FormNeeded />
              </label>
            </div>
          </div>
          <div className="">
            {rateMembers &&
              rateMembers.map((item, i) => (
                <div className={`d-flex mb-3 gap-2`} key={i}>
                  <div className="w-50">
                    <input
                      type="text"
                      className="form-control height-md text-truncate"
                      style={{ fontSize: "0.9rem", height: "2.7rem" }}
                      value={item.meta.name}
                      disabled={loader || submit || true}
                      placeholder=""
                    ></input>
                  </div>
                  <div className="w-50">
                    <input
                      type="text"
                      className="form-control height-md"
                      style={{ fontSize: "0.9rem", height: "2.7rem" }}
                      value={item.rate.nmbr}
                      onChange={({ target }) => {
                        handleRatesMembers(i, target.value);
                      }} //.replace(/[^0-9]/g, '')
                      disabled={loader || submit}
                      placeholder=""
                    ></input>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div
          className={
            data.format == "events" && data.item !== "" ? "" : "d-none"
          }
        >
          <p className="text-normal m-0">3. Rates</p>
          <WebbDividerSmall />
          <div className={`d-flex mb-1 gap-2`}>
            <div className="w-50">
              <label className="form-label text-small">
                Audience <FormNeeded />
              </label>
            </div>
            <div className="w-50">
              <label className="form-label text-small">
                Rates <FormNeeded />
              </label>
            </div>
          </div>
          <div className="">
            {rateEvents &&
              rateEvents.map((item, i) => (
                <div className={`d-flex mb-3 gap-2`} key={i}>
                  <div className="w-50">
                    <input
                      type="text"
                      className="form-control height-md text-truncate"
                      style={{ fontSize: "0.9rem", height: "2.7rem" }}
                      value={item.meta.name}
                      disabled={loader || submit || true}
                      placeholder=""
                    ></input>
                  </div>
                  <div className="w-50">
                    <input
                      type="text"
                      className="form-control height-md"
                      style={{ fontSize: "0.9rem", height: "2.7rem" }}
                      value={item.rate.nmbr}
                      onChange={({ target }) => {
                        handleRatesEvents(
                          i,
                          target.value.replace(/[^0-9]/g, "")
                        );
                      }} //
                      disabled={loader || submit}
                      placeholder=""
                    ></input>
                  </div>
                </div>
              ))}
          </div>
        </div>

        
        <WebbDividerMedium />
        <div className="">
          <p className="m-0">{memo}</p>
        </div>

        <div className={submit ? "d-none" : ""}>
          <WebbDividerMedium />
          <div className="d-flex justify-content-between">
            <button
              className={`btn btn-light border back-color-wite rounded-xx text-small`}
              type="button"
              disabled={loader || submit}
              onClick={() => {
                navigate(-1);
              }}
            >
              {loader ? "Please Wait..." : "Cancel"}
            </button>

            <button
              className={`btn btn-primary border-none rounded-xx text-small`}
              type="button"
              disabled={!form || loader || submit}
              onClick={() => {
                handleSubmit();
              }}
            >
              {loader ? "Please Wait..." : "Continue"}
            </button>
          </div>
        </div>

        <WebbDividerSmall />
      </div>
    </>
  );
}
