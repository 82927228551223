// init
import axios from "axios";
import services from "../data.services/data-services-main.json";
import { encrypt, decrypt } from "./srvc-encr-node";

const base = services.data.find((x) => x.code == "assets").link;

// -----------------

export const AssetUnitCreate = async (item) => {
  const basx = base + "/units/create";
  const head = {
    "Content-Type": "application/json",
    Authorization: item.client,
  };

  const datx = { data: item.data, srvc: item.service };
  const payload = await encrypt(datx, item.secret);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(result.data.data, item.secret);
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const AssetUnitDetails = async (item) => {
  let endpoint;
  switch (item.type) {
    case "academics":
      endpoint = "/academics/units/details";
      break;
    case "work":
      endpoint = "/work/units/details";
      break;
    case "social":
      endpoint = "/social/units/details";
      break;

    case "events":
    case "awards":
    default:
      endpoint = "/units/details";
      break;
  }

  const basx = base + endpoint;
  const head = {
    "Content-Type": "application/json",
    Authorization: item.client,
  };
  const datx = { data: item.data, srvc: item.service };
  const payload = await encrypt(datx, item.secret);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(result.data.data, item.secret);
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const AssetEdit = async (item) => {
  let endpoint;
  switch (item.type) {
    case "academics":
      endpoint = "/academics/assets/edit";
      break;
    case "work":
      endpoint = "/work/assets/edit";
      break;
    case "social":
      endpoint = "/social/assets/edit";
      break;

    case "events":
    case "awards":
    default:
      endpoint = "/assets/edit";
      break;
  }

  const basx = base + endpoint;
  const head = {
    "Content-Type": "application/json",
    Authorization: item.client,
  };
  const datx = { data: item.data, srvc: item.service };
  const payload = await encrypt(datx, item.secret);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(result.data.data, item.secret);
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const unitsMint = async (item) => {
  let endpoint;
  switch (item.type) {
    case "academics":
      endpoint = "/academics/units/status/mint/set";
      break;
    case "work":
      endpoint = "/work/units/status/mint/set";
      break;
    case "social":
      endpoint = "/social/units/status/mint/set";
      break;

    case "events":
    case "awards":
    default:
      endpoint = "/units/status/mint/set";
      break;
  }

  const basx = base + endpoint;
  const head = {
    "Content-Type": "application/json",
    Authorization: item.client,
  };
  const datx = { data: item.data, srvc: item.service };
  const payload = await encrypt(datx, item.secret);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(result.data.data, item.secret);
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const unitsLive = async (item) => {
  let endpoint;
  switch (item.type) {
    case "academics":
      endpoint = "/academics/units/status/live/set";
      break;
    case "work":
      endpoint = "/work/units/status/live/set";
      break;
    case "social":
      endpoint = "/social/units/status/live/set";
      break;

    case "events":
    case "awards":
    default:
      endpoint = "/units/status/live/set";
      break;
  }

  const basx = base + endpoint;
  const head = {
    "Content-Type": "application/json",
    Authorization: item.client,
  };
  const datx = { data: item.data, srvc: item.service };
  const payload = await encrypt(datx, item.secret);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(result.data.data, item.secret);
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const unitsScan = async (item) => {
  let endpoint;
  switch (item.type) {
    case "academics":
      endpoint = "/academics/units/status/scan/set";
      break;
    case "work":
      endpoint = "/work/units/status/scan/set";
      break;
    case "social":
      endpoint = "/social/units/status/scan/set";
      break;

    case "events":
    case "awards":
    default:
      endpoint = "/units/status/scan/set";
      break;
  }

  const basx = base + endpoint;
  const head = {
    "Content-Type": "application/json",
    Authorization: item.client,
  };
  const datx = { data: item.data, srvc: item.service };
  const payload = await encrypt(datx, item.secret);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(result.data.data, item.secret);
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const unitsBook = async (item) => {
  let endpoint;
  switch (item.type) {
    case "academics":
      endpoint = "/academics/units/status/book/set";
      break;
    case "work":
      endpoint = "/work/units/status/book/set";
      break;
    case "social":
      endpoint = "/social/units/status/book/set";
      break;

    case "events":
    case "awards":
    default:
      endpoint = "/units/status/book/set";
      break;
  }

  const basx = base + endpoint;
  const head = {
    "Content-Type": "application/json",
    Authorization: item.client,
  };
  const datx = { data: item.data, srvc: item.service };
  const payload = await encrypt(datx, item.secret);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(result.data.data, item.secret);
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const unitsShow = async (item) => {
  let endpoint;
  switch (item.type) {
    case "academics":
      endpoint = "/academics/units/status/discover/set";
      break;
    case "work":
      endpoint = "/work/units/status/discover/set";
      break;
    case "social":
      endpoint = "/social/units/status/discover/set";
      break;

    case "events":
    case "awards":
    default:
      endpoint = "/units/status/discover/set";
      break;
  }

  const basx = base + endpoint;
  const head = {
    "Content-Type": "application/json",
    Authorization: item.client,
  };
  const datx = { data: item.data, srvc: item.service };
  const payload = await encrypt(datx, item.secret);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(result.data.data, item.secret);
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const unitsResale = async (item) => {
  let endpoint;
  switch (item.type) {
    case "academics":
      endpoint = "/academics/units/status/resale/set";
      break;
    case "work":
      endpoint = "/work/units/status/resale/set";
      break;
    case "social":
      endpoint = "/social/units/status/resale/set";
      break;

    case "events":
    case "awards":
    default:
      endpoint = "/units/status/resale/set";
      break;
  }

  const basx = base + endpoint;
  const head = {
    "Content-Type": "application/json",
    Authorization: item.client,
  };
  const datx = { data: item.data, srvc: item.service };
  const payload = await encrypt(datx, item.secret);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(result.data.data, item.secret);
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const unitsShare = async (item) => {
  let endpoint;
  switch (item.type) {
    case "academics":
      endpoint = "/academics/units/status/share/set";
      break;
    case "work":
      endpoint = "/work/units/status/share/set";
      break;
    case "social":
      endpoint = "/social/units/status/share/set";
      break;

    case "events":
    case "awards":
    default:
      endpoint = "/units/status/share/set";
      break;
  }

  const basx = base + endpoint;
  const head = {
    "Content-Type": "application/json",
    Authorization: item.client,
  };
  const datx = { data: item.data, srvc: item.service };
  const payload = await encrypt(datx, item.secret);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(result.data.data, item.secret);
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const AssetUnitDataMintSet = async (item) => {
  let endpoint;
  switch (item.type) {
    case "academics":
      endpoint = "/academics/units/data/mint/set";
      break;
    case "work":
      endpoint = "/work/units/data/mint/set";
      break;
    case "social":
      endpoint = "/social/units/data/mint/set";
      break;

    case "events":
    case "awards":
    default:
      endpoint = "/units/data/mint/set";
      break;
  }

  const basx = base + endpoint;
  const head = {
    "Content-Type": "application/json",
    Authorization: item.client,
  };
  const datx = { data: item.data, srvc: item.service };
  const payload = await encrypt(datx, item.secret);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(result.data.data, item.secret);
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const AssetUnitDataBookSet = async (item) => {
  const basx = base + "/units/data/book/set";
  const head = {
    "Content-Type": "application/json",
    Authorization: item.client,
  };
  const datx = { data: item.data, srvc: item.service };
  const payload = await encrypt(datx, item.secret);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(result.data.data, item.secret);
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const AssetUnitDataSaleSet = async (item) => {
  const basx = base + "/units/data/sale/set";
  const head = {
    "Content-Type": "application/json",
    Authorization: item.client,
  };
  const datx = { data: item.data, srvc: item.service };
  const payload = await encrypt(datx, item.secret);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(result.data.data, item.secret);
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const AssetUnitRateSet = async (item) => {
  let endpoint;
  switch (item.type) {
    case "academics":
      endpoint = "/academics/units/rate/set";
      break;
    case "work":
      endpoint = "/work/units/rate/set";
      break;
    case "social":
      endpoint = "/social/units/rate/set";
      break;

    case "events":
    case "awards":
    default:
      endpoint = "/units/rate/set";
      break;
  }

  const basx = base + endpoint;
  const head = {
    "Content-Type": "application/json",
    Authorization: item.client,
  };
  const datx = { data: item.data, srvc: item.service };
  const payload = await encrypt(datx, item.secret);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(result.data.data, item.secret);
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const AssetUnitListMembers = async (item) => {
  let endpoint;
  switch (item.type) {
    case "academics":
      endpoint = "/academics/units/list/members";
      break;
    case "work":
      endpoint = "/work/units/list/members";
      break;
    case "social":
      endpoint = "/social/units/list/members";
      break;
    case "events":
    case "awards":
    default:
      endpoint = "/units/list/members";
      break;
  }

  const basx = base + endpoint;
  const head = {
    "Content-Type": "application/json",
    Authorization: item.client,
  };
  const datx = { data: item.data, srvc: item.service };
  const payload = await encrypt(datx, item.secret);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(result.data.data, item.secret);
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const CollectUserData = async (item) => {
  let endpoint;
  switch (item.type) {
    case "academics":
      endpoint = "/academics/assets/formats/create";
      break;
    case "work":
      endpoint = "/work/assets/formats/create";
      break;
    case "social":
      endpoint = "/social/assets/formats/create";
      break;

    case "events":
    case "awards":
    default:
      endpoint = "/assets/formats/create";
      break;
  }

  const basx = base + endpoint;
  const head = {
    "Content-Type": "application/json",
    Authorization: item.client,
  };
  const datx = { data: item.data, srvc: item.service };
  const payload = await encrypt(datx, item.secret);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(result.data.data, item.secret);
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};
