import { useState } from "react";
import { useNavigate } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import { TeamSocialUpdate } from "../../services/srvc-users-creators-realm";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

export default function TeamSocialAddModule() {
  const navigate = useNavigate();
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const [loader, setLoader] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [memo, setMemo] = useState("");

  const [data, setData] = useState({
    linkedin: "",
    facebook: "",
    twitter: "",
    instagram: "",
    organizationId: "",
  });

  const [form, setForm] = useState(false);

  // Validation Functions
  const isValidLinkedIn = (url) =>
    /^https:\/\/([a-z]{2,3}\.)?linkedin\.com\/.*$/i.test(url);

  const isValidFacebook = (url) =>
    /^https:\/\/([a-z]{2,3}\.)?facebook\.com\/.*$/i.test(url);

  const isValidTwitter = (url) =>
    /^https:\/\/([a-z]{2,3}\.)?twitter\.com\/.*$/i.test(url);

  const isValidInstagram = (url) =>
    /^https:\/\/([a-z]{2,3}\.)?instagram\.com\/.*$/i.test(url);
  const isValidOrganizationId = (url) =>
    /^https:\/\/(www\.)?linkedin\.com\/company\/\d{9}\/?$/i.test(url);

  // UseEffect for form validation
  useState(() => {
    const allValid =
      isValidLinkedIn(data.linkedin) &&
      isValidFacebook(data.facebook) &&
      isValidTwitter(data.twitter) &&
      isValidInstagram(data.instagram) &&
      isValidOrganizationId(data.organizationId);
    setForm(allValid);
  }, [data]);

  const handleChange = (key, value) => {
    setData({ ...data, [key]: value });
  };

  const handleSubmit = async () => {
    if (asset) {
      setLoader(true);
      setMemo("Submitting your data...");
      setSubmit(true);

      const datx = {
        team: asset.item,
        linkedin: data.linkedin,
        twitter: data.twitter,
        facebook: data.facebook,
        instagram: data.instagram,
        organizationId: data.organizationId,
      };

      const result = await TeamSocialUpdate({
        data: datx,
        service: asset?.creds?.service,
        client: asset?.creds?.client,
        secret: asset?.creds?.secret,
      });

      // Simulate API call or actual submission logic
      if (result.stat) {
        setLoader(false);
        setMemo("Submission Successful!");
        navigate(-1);
      }
    } else {
      alert("Please ensure all links are valid.");
    }
  };

  return (
    <>
      <div className="mx-3">
        <h2 className="text-normal m-0">Social Links</h2>
        <WebbDividerSmall />
      </div>

      <div className="back-color-wite p-3 rounded-xd">
        <WebbDividerSmall />

        <div className="mb-3">
          <label className="form-label text-small">LinkedIn</label>
          <input
            type="text"
            className="form-control height-md"
            value={data.linkedin}
            onChange={({ target }) => handleChange("linkedin", target.value)}
            disabled={loader || submit}
            placeholder="https://linkedin.com/in/username"
          />
          {data.linkedin && !isValidLinkedIn(data.linkedin) && (
            <div className="mt-2 text-small text-danger">
              Please enter a valid LinkedIn URL.
            </div>
          )}
        </div>
        <div className="mb-3">
          <label className="form-label text-small">Organization Id</label>
          <input
            type="text"
            className="form-control height-md"
            value={data.organizationId}
            onChange={({ target }) =>
              handleChange("organizationId", target.value)
            }
            disabled={loader || submit}
            placeholder="https://linkedin.com/company/organizationId"
          />
          {data.organizationId &&
            !isValidOrganizationId(data.organizationId) && (
              <div className="mt-2 text-small text-danger">
                Please enter a valid LinkedIn organization URL.
              </div>
            )}
        </div>

        <div className="mb-3">
          <label className="form-label text-small">Facebook</label>
          <input
            type="text"
            className="form-control height-md"
            value={data.facebook}
            onChange={({ target }) => handleChange("facebook", target.value)}
            disabled={loader || submit}
            placeholder="https://facebook.com/username"
          />
          {data.facebook && !isValidFacebook(data.facebook) && (
            <div className="mt-2 text-small text-danger">
              Please enter a valid Facebook URL.
            </div>
          )}
        </div>

        <div className="mb-3">
          <label className="form-label text-small">Twitter</label>
          <input
            type="text"
            className="form-control height-md"
            value={data.twitter}
            onChange={({ target }) => handleChange("twitter", target.value)}
            disabled={loader || submit}
            placeholder="https://twitter.com/username"
          />
          {data.twitter && !isValidTwitter(data.twitter) && (
            <div className="mt-2 text-small text-danger">
              Please enter a valid Twitter URL.
            </div>
          )}
        </div>

        <div className="mb-1">
          <label className="form-label text-small">Instagram</label>
          <input
            type="text"
            className="form-control height-md"
            value={data.instagram}
            onChange={({ target }) => handleChange("instagram", target.value)}
            disabled={loader || submit}
            placeholder="https://instagram.com/username"
          />
          {data.instagram && !isValidInstagram(data.instagram) && (
            <div className="mt-2 text-small text-danger">
              Please enter a valid Instagram URL.
            </div>
          )}
        </div>

        <p className="m-0 mt-3">{memo}</p>
        <WebbDividerMedium />

        <div className="d-flex justify-content-between">
          <button
            className="btn btn-light border back-color-wite rounded-xx text-small"
            disabled={loader || submit}
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>

          <button
            className="btn btn-primary border-none rounded-xx text-small"
            disabled={loader || submit}
            onClick={handleSubmit}
          >
            {loader ? "Please Wait..." : "Submit"}
          </button>
        </div>

        <WebbDividerSmall />
      </div>
    </>
  );
}
