import React, { useState } from "react";
import Draggable from "react-draggable";
import html2canvas from "html2canvas"; // Import the library
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import { TransfersAssetListUnit } from "../../services/srvc-transfers-assets-realm";
import { DocumentTempleateCreate } from "../../services/srvc-media-docs-realm";

const CertificateCanvas = ({ file, base64, asset, id }) => {
  const [memo, setMemo] = useState("");
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("User Name");
  const [certificateNumber, setCertificateNumber] = useState("Certificate Number");
  const [issueDate, setIssueDate] = useState("Issue Date");
  const [namePosition, setNamePosition] = useState({ x: 10, y: 5 });
  const [certificateNumberPosition, setCertificateNumberPosition] = useState({
    x: 250,
    y: 5,
  });
  const [issueDatePosition, setIssueDatePosition] = useState({
    x: 500,
    y: 5,
  });
  const [savedData, setSavedData] = useState(null);

  const [fontStyles, setFontStyles] = useState({
    fontStyle: "normal",
    color: "white",
    fontSize: "18", // Default font size
  });

  const styles = [
    { label: "Normal", style: { fontStyle: "normal", fontWeight: "normal" } },
    { label: "Italic", style: { fontStyle: "italic" } },
    { label: "Bold", style: { fontWeight: "bold" } },
    { label: "Cursive", style: { fontFamily: "cursive" } },
    { label: "Underline", style: { textDecoration: "underline" } },
    { label: "Uppercase", style: { textTransform: "uppercase" } },
    { label: "Lowercase", style: { textTransform: "lowercase" } },
    { label: "StrikeThrough", style: { textDecoration: "line-through" } },
    { label: "Monospace", style: { fontFamily: "monospace" } },
    {
      label: "Shadow",
      style: { textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)" },
    },
  ];

  // Update position during drag
  const handleDragName = (e, data) => {
    setNamePosition({ x: data.x, y: data.y });
  };

  const handleDragCertificateNumber = (e, data) => {
    setCertificateNumberPosition({ x: data.x, y: data.y });
  };
  const handleDragIssueDate = (e, data) => {
    setIssueDatePosition({ x: data.x, y: data.y });
  };

  // Save the name and position
  const handleSave = () => {
    // setSavedData({ name, position });
    alert("Name and position saved!");
  };

  const handleCreate = async () => {
    setLoader(true);
    setMemo("Please wait...");
    const datx = {
      filedata: base64,
      unit: id,
      document: {
        sort: "academics",
        format: "certificate",
      },
      creator: asset?.item,
      values: [
        {
          type: "name",
          value: {
            x: parseInt(namePosition?.x).toString(),
            y: parseInt(namePosition?.y).toString(),
            fontStyles: fontStyles,
          },
        },
        {
          type: "certificateNumber",
          value: {
            x: parseInt(certificateNumberPosition?.x).toString(),
            y: parseInt(certificateNumberPosition?.y).toString(),
            fontStyles: fontStyles,
          },
        },
        {
          type: "issueDate",
          value: {
            x: parseInt(issueDatePosition?.x).toString(),
            y: parseInt(issueDatePosition?.y).toString(),
            fontStyles: fontStyles,
          },
        },
      ],
    };

    const result = await DocumentTempleateCreate({
      data: datx,
      service: asset?.creds?.service,
      client: asset?.creds?.client,
      secret: asset?.creds?.secret,
    });
    setLoader(false);
  };

  // Download the edited certificate
  // const handleDownload = async () => {
  //   const canvasElement = document.getElementById("certificateCanvas");

  //   if (!canvasElement) {
  //     alert("Certificate canvas not found!");
  //     return;
  //   }

  //   const canvas = await html2canvas(canvasElement);
  //   const link = document.createElement("a");
  //   link.href = canvas.toDataURL("image/png"); // Convert canvas to image URL
  //   link.download = "certificate.png"; // Set the file name
  //   link.click(); // Trigger the download
  // };

  return (
    <div className="mx-1">
      {/* Name Input */}
      <div className="mb-3">
        <label htmlFor="nameInput" className="form-label">
          Name:
        </label>
        <input
          type="text"
          id="nameInput"
          className="form-control"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>

      {/* Certificate Number Input */}
      <div className="mb-3">
        <label htmlFor="certificateNumberInput" className="form-label">
          Certificate Number:
        </label>
        <input
          type="text"
          id="certificateNumberInput"
          className="form-control"
          value={certificateNumber}
          onChange={(e) => setCertificateNumber(e.target.value)}
        />
      </div>

      {/* Issue Date Input */}
      <div className="mb-3">
        <label htmlFor="issueDateInput" className="form-label">
          Issue Date:
        </label>
        <input
          type="date"
          id="issueDateInput"
          className="form-control"
          value={issueDate}
          onChange={(e) => setIssueDate(e.target.value)}
        />
      </div>

      {/* Styled Name Options */}
      <div className={name.length > 3 ? "mb-3 d-none" : "d-none"}>
        <label className="form-label">Select a Styled Name:</label>
        <div className="d-flex flex-wrap gap-2">
          {styles.map((option, index) => (
            <div
              key={index}
              className="p-2 border rounded cursor-pointer"
              style={{
                ...option.style,
                backgroundColor: "#f0f0f0",
                color: "black",
                cursor: "pointer",
              }}
              onClick={() =>
                setFontStyles((prev) => ({ ...prev, ...option.style }))
              }
            >
              {name}
            </div>
          ))}
        </div>
      </div>

      {/* Font Color Picker for Name */}
      {/* <div className="mb-3">
        <label htmlFor="colorPicker" className="form-label">
          Choose Text Color:
        </label>
        <input
          type="color"
          id="colorPicker"
          className="form-control form-control-color w-auto"
          value={fontStyles.color}
          onChange={(e) => {
            setFontStyles((prev) => ({ ...prev, color: e.target.value }));
          }}
        />
      </div> */}

      <div className="mb-3">
        <label htmlFor="colorPicker" className="form-label">
          Choose Text Color:
        </label>
        <div className="d-flex gap-2">
          <button
            type="button"
            className="btn"
            style={{
              backgroundColor: "black",
              color: "white",
              width: "25px",
              height: "25px",
            }}
            onClick={() =>
              setFontStyles((prev) => ({ ...prev, color: "black" }))
            }
          >
            <span className="visually-hidden">Black</span>
          </button>
          <button
            type="button"
            className="btn"
            style={{
              backgroundColor: "white",
              border: "1px solid #ccc",
              width: "25px",
              height: "25px",
            }}
            onClick={() =>
              setFontStyles((prev) => ({ ...prev, color: "white" }))
            }
          >
            <span className="visually-hidden">White</span>
          </button>
        </div>
      </div>

      {/* Font Size Picker for Name */}
      <div className="mb-3">
        <label htmlFor="fontSizePicker" className="form-label">
          Choose Font Size:
        </label>
        <input
          type="range"
          id="fontSizePicker"
          className="form-control"
          min="10"
          max="50"
          step="1"
          value={parseInt(fontStyles.fontSize)}
          onChange={(e) => {
            setFontStyles((prev) => ({
              ...prev,
              fontSize: `${e.target.value}`,
            }));
          }}
        />
        <span>{fontStyles.fontSize}</span> {/* Display the font size */}
      </div>

      {/* Certificate Canvas */}
      <div
        id="certificateCanvas" // Add an ID for the canvas
        className="border position-relative rounded-sm"
        style={{
          width: "100%",
          height: "450px",
          backgroundImage: `url(${file || "/crt.jpg"})`, // Replace with your certificate background image
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Draggable Name */}
        <Draggable
          position={namePosition}
          onDrag={handleDragName}
          bounds="parent"
        >
          <div
            className="position-absolute"
            style={{
              cursor: "move",
              fontSize: fontStyles.fontSize, // Apply dynamic font size
              fontWeight: "bold",
              ...fontStyles, // Apply all selected styles
            }}
          >
            {name}
          </div>
        </Draggable>

        {/* Draggable Certificate Number */}
        <Draggable
          position={certificateNumberPosition}
          onDrag={handleDragCertificateNumber}
          bounds="parent"
        >
          <div
            className="position-absolute"
            style={{
              cursor: "move",
              fontSize: fontStyles.fontSize, // Apply dynamic font size
              fontWeight: "bold",
              ...fontStyles, // Apply all selected styles
            }}
          >
            {certificateNumber}
          </div>
        </Draggable>

        {/* Draggable Issue Date */}
        <Draggable
          position={issueDatePosition}
          onDrag={handleDragIssueDate}
          bounds="parent"
        >
          <div
            className="position-absolute"
            style={{
              cursor: "move",
              fontSize: fontStyles.fontSize, // Apply dynamic font size
              fontWeight: "bold",
              ...fontStyles, // Apply all selected styles
            }}
          >
            {issueDate}
          </div>
        </Draggable>
      </div>
      <WebbDividerSmall />
      <WebbDividerMedium />
      {/* Save Button */}
      <div className="d-flex justify-content-between">
        <button
          className="btn btn-primary w-auto rounded-xx text-small"
          onClick={handleSave}
        >
          Save Position
        </button>
        <button
          className="btn btn-primary w-auto rounded-xx text-small ms-3"
          // onClick={handleDownload}
          onClick={handleCreate}
        >
          Create Certificate
        </button>
      </div>
      {/* <WebbDividerMedium /> */}

      {/* Display Saved Data */}
      {savedData && (
        <div className="mt-3 text-center d-none">
          <h5>Saved Data:</h5>
          <p>
            <strong>Name:</strong> {savedData.name}
          </p>
          {/* <p>
            <strong>Position:</strong> X: {savedData.position.x}px, Y:{" "}
            {savedData.position.y}px
          </p> */}
        </div>
      )}
    </div>
  );
};

export default CertificateCanvas;
