// content
import { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { CSVLink } from "react-csv";

import WebbDividerMedium from "../webx/webb-divider-md";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import {
  AssetUnitCreate,
  AssetUnitDetails,
  CollectUserData,
} from "../../services/srvc-assets-units-realm";

import { AssetUnitDataMintSet } from "../../services/srvc-assets-units-realm";
import {
  AssetUnitDataBookSet,
  AssetUnitDataSaleSet,
} from "../../services/srvc-assets-units-realm";

import {
  TransfersAssetClaim,
  TransfersAssetCreate,
} from "../../services/srvc-transfers-assets-realm";
import {
  TeamContactDetails,
  TeamMemberList,
} from "../../services/srvc-users-creators-realm";

import { TransfersAssetListUnit } from "../../services/srvc-transfers-assets-realm";

import { XLSXFileDownload } from "../../content/webx/filesave-xlsx";
import { IndividualOfferCreate } from "../../services/srvc-offers-realm";
import MailCreate from "../../views.network/network-mail-create";
import { AssetCreateAcademics } from "../../services/srvc-assets-realm";

const listactions = require("../../data.static/data-units-actionx.json").data;

const fileheaders = [
  { label: "User", key: "user" },
  { label: "Email", key: "Email" },
  { label: "Date", key: "Date" },
  { label: "Status", key: "Status" },
  { label: "Amount", key: "Amount" },
  { label: "Size", key: "Size" },
];

export default function UnitActionsModule(props) {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();
  const { id, type } = useParams();

  const [loader, setLoader] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);

  const [actions, setActions] = useState();
  const [memo, setMemo] = useState("");
  const [mail, setMail] = useState("");
  const [rate, setRate] = useState("");
  const [unit, setUnit] = useState("1");

  const [data, setData] = useState({
    mxcount: "",
    mxrate: "",
    mxticker: "INR",
    txminter: "",
    txminterstatus: false,
    txunit: "",
    txcount: "1",
  });

  const [showComponent, setShowComponent] = useState(false);
  const [ticker, setTicker] = useState("INR");
  const [download, setDownload] = useState([]);

  const [checkboxes, setCheckboxes] = useState({
    name: false,
    email: false,
    mobile: false,
    linkedin: false,
    address: false,
  });

  // Step 2: Handle checkbox change
  const handleCheckboxChange = (key) => {
    setCheckboxes((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  // Step 3: Handle form submission
  const handleSubmit = async () => {
    const selectedData = Object.entries(checkboxes)
      .filter(([key, value]) => value)
      .map(([key]) => key);

    console.log("Selected Data:", selectedData);
    var objArr = [];
    for (var i = 0; i < selectedData.length; i++) {
      objArr.push({ value: selectedData[i], type: "string" });
    }

    setLoader(true);
    setSubmit(true);
    setMemo("Please wait...");
    const datx = {
      feature: { format: data.format, sort: data.sort, role: "user" },
      // taxxon: listFormat.find((x) => x.item == data.item).taxxon,
      unit: id,
      // rates: data.format == "events" ? rateEvents : rateMembers,
      creator: asset.item,
      domain: data.format,
      vars: objArr,
    };
    // console.log (datx)

    const result = await CollectUserData({
      data: datx,
      service: asset?.creds?.service,
      client: asset?.creds?.client,
      secret: asset?.creds?.secret,
      type: type,
    });

    if (result.stat) {
      setDone(true);
      setMemo("Format Created Successfully");
      //navigate("/team/assets");
    } else {
      setDone(false);
      setSubmit(false);
      setMemo("Format Creation failed");
    }

    setLoader(false);
    // Perform further actions, such as sending data to the backend
  };

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);
        const result = listactions.filter((x) => x.user.includes(asset.role));
        setActions(result);

        setUnit(props.data);

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, [props]);

  const handleUnitCreate = async () => {};

  const handleUnitCreateReset = async () => {
    setData({ ...data, mxcount: "", mxrate: "" });
    setSubmit(false);
    setMemo("");
  };

  const handleUnitDataMintSet = async () => {
    setLoader(true);
    setSubmit(true);
    setMemo("Please Wait...");

    const datx = {
      asset: unit?.asset?.item || "",
      unit: id,
      count: { number: data?.mxcount.toString() || "0", ticker: "IAM" },
      credit: { item: asset.item, mail: asset.mail, name: asset.name },
      crxx: null,
      debit: { item: "******", mail: "******", name: "BharatId Reserve" },
      meta: props.data.meta,
      mode: "sale",
      init: true,
      rate: { number: "0", ticker: "******" },
      webx: props.data.webx,
      format: type,
    };

    const result = await TransfersAssetClaim({
      data: datx,
      service: asset?.creds?.service,
      client: asset?.creds?.client,
      secret: asset?.creds?.secret,
    });
    // console.log (result)
    if (result.stat) {
      setMemo(
        "Success: Unit Minted. Please refresh the page after 30 seconds."
      );
      setDone(true);
    } else {
      setMemo("Error: Unit Not Minted");
      setDone(false);
    }

    setSubmit(false);
    setLoader(false);
  };

  const UserOfferCreate = async () => {
    setLoader(true);
    setSubmit(true);
    setMemo("Please Wait...");

    //console.log(data.txminter)
    const userx = await TeamContactDetails({
      data: { user: data.txminter, team: asset.item },
      service: asset?.creds?.service,
      client: asset?.creds?.client,
      secret: asset?.creds?.secret,
    });
    //  console.log(userx)

    if (!userx.stat) {
      setMemo("User not found. Please add to your Network");
      setSubmit(false);
      setLoader(false);
      return;
    }

    var datx = {
      user: {
        name: userx?.data?.name,
        mail: userx?.data?.mail,
        item: userx?.data?.item,
      },
      asset: { item: unit.asset.item },
      unit: { item: id },
      format: type,
      creator: [props?.data?.creator],
      cred: null,
      rate: { number: rate || "0", ticker: "INR" },
    };

    var res = await IndividualOfferCreate({
      data: datx,
      service: asset?.creds?.service,
      client: asset?.creds?.client,
      secret: asset?.creds?.secret,
    });
    // console.log(res)
    if (res.stat) {
      setMemo("Success: Offer Created");
      setDone(true);
    } else {
      setMemo("Error: Server Error");
      setDone(false);
    }

    setSubmit(false);
    setLoader(false);
  };

  const handleUnitTransferReset = async () => {
    setData({
      ...data,
      txminter: "",
      txminterstatus: "",
      txunit: "",
      txcount: "1",
    });
    setSubmit(false);
    setMemo("");
  };

  const handleDownload = async () => {
    setLoader(true);
    setMemo("Please wait...");
    const result = await TransfersAssetListUnit({
      data: { unit: id, index: "1", items: "9999" },
      service: asset?.creds?.service,
      client: asset?.creds?.client,
      secret: asset?.creds?.secret,
    });
    // console.log(result)
    // if (result.stat) setDownload(Array.from(result.data.list, x => { return {user: x.user.name,Email:x.user.mail,Date:x.created,Status:x.status,Amount:x.sale.number,Size:x.size.number}}))

    var fileName = `statement-${id}`;
    XLSXFileDownload(result.data.list, fileName);

    setLoader(false);
  };

  const handleClick = (live, link) => {
    if (live) navigate(`/${asset.role}/${link}`);
  };

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val });
  };

  // console.log(unit)

  return (
    <>
      <div className="back-color-wite rounded-xd p-1">
        {actions &&
          actions.map((item, i) =>
            item.actv ? (
              <div
                className={`d-flex p-2 align-middle 
            ${item.live ? "cursor hidark rounded-wd" : "text-color-tint"}`}
                style={{ height: "2.4rem" }}
                key={i}
                data-bs-toggle="modal"
                data-bs-target={item.link}
              >
                <div className="">
                  <i
                    className={`m-0 p-0 ${item.icon} `}
                    style={{ fontSize: "1.35rem" }}
                  ></i>
                </div>
                <div className="ms-2">
                  <p className={`m-0 p-0 text-nowrap`}>
                    <span className="text-small align-middle">{item.name}</span>
                  </p>
                </div>
                <div className="ms-auto ">
                  <i
                    className={`m-0 p-0 bx bx-chevron-right`}
                    style={{ fontSize: "1.35rem" }}
                  ></i>
                </div>
              </div>
            ) : (
              ""
            )
          )}

        <div
          className={`d-flex p-2 align-middle cursor hidark rounded-wd`}
          style={{ height: "2.4rem" }}
          onClick={() => handleClick(true, `assets/collectdata/${type}/${id}`)}
        >
          <div className="">
            <i
              className={"m-0 p-0 bx bx-file"}
              style={{ fontSize: "1.5em" }}
            ></i>
          </div>
          <div className="ms-2">
            <p className={`m-0 p-0 text-nowrap`}>
              <span className="text-small align-middle">Collect Data</span>
            </p>
          </div>
          <div className="ms-auto ">
            <i
              className={`m-0 p-0 bx bx-chevron-right`}
              style={{ fontSize: "1.5em" }}
            ></i>
          </div>
        </div>
        <div
          className={`d-flex p-2 align-middle cursor hidark rounded-wd`}
          style={{ height: "2.4rem" }}
          onClick={() => handleClick(true, "network/mail")}
        >
          <div className="">
            <i
              className={"m-0 p-0 bx bx-envelope"}
              style={{ fontSize: "1.5em" }}
            ></i>
          </div>
          <div className="ms-2">
            <p className={`m-0 p-0 text-nowrap`}>
              <span className="text-small align-middle">Send Mail</span>
            </p>
          </div>
          <div className="ms-auto ">
            <i
              className={`m-0 p-0 bx bx-chevron-right`}
              style={{ fontSize: "1.5em" }}
            ></i>
          </div>
        </div>
        {type == "academics" && (
          <div
            className={`d-flex p-2 align-middle cursor hidark rounded-wd`}
            style={{ height: "2.4rem" }}
            onClick={() => handleClick(true, `create/template/${id}`)}
          >
            <div className="">
              <i
                className={"m-0 p-0 bx bx-file-blank"}
                style={{ fontSize: "1.5em" }}
              ></i>
            </div>
            <div className="ms-2">
              <p className={`m-0 p-0 text-nowrap`}>
                <span className="text-small align-middle">
                  Create Templet Certificate
                </span>
              </p>
            </div>
            <div className="ms-auto ">
              <i
                className={`m-0 p-0 bx bx-chevron-right`}
                style={{ fontSize: "1.5em" }}
              ></i>
            </div>
          </div>
        )}
      </div>

      {/* action modal */}
      <div
        className="modal fade"
        id="modUnitCreate"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content p-0 m-0 rounded-xd">
            <div className="modal-header border-none">
              <p className="text-normal align-middle m-0 p-0">Create Unit</p>
              <button
                type="button"
                className="btn-close text-small"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => handleUnitCreateReset()}
              ></button>
            </div>

            {/* modal content */}
            <div className="modal-body border-none">
              <div className="mb-3">
                <label className="form-label text-small">
                  Quantity <FormNeeded />
                </label>
                <input
                  type="text"
                  className="form-control height-md"
                  style={{ fontSize: "0.9rem", height: "2.7rem" }}
                  value={data.mxcount}
                  onChange={({ target }) => {
                    handleChange("mxcount", target.value);
                  }}
                  disabled={loader || submit}
                  placeholder="123"
                ></input>
              </div>

              <div className="mb-3">
                <label className="form-label text-small">
                  Rate <FormNeeded />
                </label>
                <input
                  type="text"
                  className="form-control height-md"
                  style={{ fontSize: "0.9rem", height: "2.7rem" }}
                  value={data.mxrate}
                  onChange={({ target }) => {
                    handleChange("mxrate", target.value);
                  }}
                  disabled={loader || submit}
                  placeholder="123"
                ></input>
              </div>

              <p className="text-small">{memo}</p>
            </div>

            {!loader && !done && (
              <div className="d-flex modal-footer border-none">
                <div className="">
                  <button
                    className="btn btn-outline-secondary btn-sm text-small rounded-xx"
                    data-bs-dismiss="modal"
                    onClick={() => handleUnitCreateReset()}
                  >
                    Cancel
                  </button>
                </div>
                <div className="ms-auto">
                  <button
                    className="btn btn-primary btn-sm text-small rounded-xx"
                    onClick={() => handleUnitCreate()}
                    disabled={!data.mxcount || data.mxcount == 0 || submit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* action modal */}
      <div
        className="modal fade"
        id="modUnitMint"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content p-0 m-0 rounded-xd">
            <div className="modal-header border-none">
              <p className="text-normal align-middle m-0 p-0">Create Unit</p>
              <button
                type="button"
                className="btn-close text-small"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => handleUnitCreateReset()}
              ></button>
            </div>

            {/* modal content */}
            <div className="modal-body border-none">
              {!loader && !done && (
                <div className="mb-3">
                  <label className="form-label text-small">
                    Number of Units <FormNeeded />
                  </label>
                  <input
                    type="text"
                    className="form-control height-md"
                    style={{ fontSize: "0.9rem", height: "2.7rem" }}
                    value={data.mxcount}
                    onChange={({ target }) => {
                      handleChange("mxcount", target.value);
                    }}
                    disabled={loader || submit}
                    placeholder="123"
                  ></input>
                </div>
              )}

              <div className="mb-3 d-none">
                <label className="form-label text-small">
                  Rate <FormNeeded />
                </label>
                <input
                  type="text"
                  className="form-control height-md"
                  style={{ fontSize: "0.9rem", height: "2.7rem" }}
                  value={data.mxrate}
                  onChange={({ target }) => {
                    handleChange("mxrate", target.value);
                  }}
                  disabled={loader || submit}
                  placeholder="123"
                ></input>
              </div>

              {!done && <p className="text-small">{memo}</p>}
              {done && (
                <div className="text-center">
                  <img
                    src="https://www.architecturaldigest.in/wp-content/themes/cntraveller/images/check-circle.gif"
                    style={{ width: "150px", height: "150px" }}
                  />
                  <p className="text-small p-2 mb-1">
                    Unit created Successfully
                  </p>
                  <p className="text-primary text-mini p-2 mb-2">
                    The created unit will be reflected after 30 to 40 seconds.
                    Please refresh the page.
                  </p>
                </div>
              )}
            </div>

            {!loader && !done && (
              <div className="d-flex modal-footer border-none">
                <div className="">
                  <button
                    className="btn btn-outline-secondary btn-sm text-small rounded-xx"
                    data-bs-dismiss="modal"
                    onClick={() => handleUnitCreateReset()}
                  >
                    Cancel
                  </button>
                </div>
                <div className="ms-auto">
                  <button
                    className="btn btn-primary btn-sm text-small rounded-xx"
                    onClick={() => handleUnitDataMintSet()}
                    disabled={!data.mxcount || data.mxcount == 0 || submit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* action modal */}
      <div
        className="modal fade"
        id="modUnitOffer"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content p-0 m-0 rounded-xd">
            <div className="modal-header border-none">
              <p className="text-normal align-middle m-0 p-0">Create Offer</p>
              <button
                type="button"
                className="btn-close text-small"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => handleUnitTransferReset()}
              ></button>
            </div>

            {/* modal content */}
            <div className="modal-body border-none">
              <div className="mb-3 d-none">
                <label className="form-label text-small">
                  Select Unit <FormNeeded />
                </label>
                <div className={""}>
                  <p className="">{unit?.meta?.name || "******"}</p>
                </div>

                <div className={""}>
                  <input
                    type="text"
                    className="form-control height-md"
                    style={{ fontSize: "0.9rem", height: "2.7rem" }}
                    value={data.txunit}
                    onChange={({ target }) => {
                      handleChange("txunit", target.value);
                    }}
                    disabled={loader || submit}
                    placeholder="123"
                  ></input>
                </div>
              </div>

              <div className="mb-3">
                <label className="form-label text-small">
                  Email <FormNeeded />
                </label>
                <input
                  type="email"
                  className="form-control height-md"
                  style={{ fontSize: "0.9rem", height: "2.7rem" }}
                  value={data.txminter}
                  onChange={({ target }) => {
                    handleChange("txminter", target.value);
                  }}
                  disabled={loader || submit}
                  placeholder="user@bharat.id"
                ></input>
              </div>

              {(type == "events" || type == "social") && (
                <div className="mb-3 d-none">
                  <label className="form-label text-small">
                    Number of Units <FormNeeded />
                  </label>
                  <input
                    type="text"
                    className="form-control height-md"
                    style={{ fontSize: "0.9rem", height: "2.7rem" }}
                    value={data.txcount}
                    onChange={({ target }) => {
                      handleChange("txcount", target.value);
                    }}
                    disabled={loader || submit || true}
                    placeholder="123"
                  ></input>
                </div>
              )}

              {(type == "events" || type == "social") && (
                <div className="mb-3">
                  <label className="form-label text-small">
                    Rate <FormNeeded />
                  </label>
                  <input
                    type="text"
                    className="form-control height-md"
                    style={{ fontSize: "0.9rem", height: "2.7rem" }}
                    value={rate}
                    onChange={({ target }) => {
                      setRate(target.value);
                    }}
                    disabled={loader || submit}
                    placeholder="123"
                  ></input>
                </div>
              )}

              <p className="text-small">{memo}</p>
            </div>
            {!loader && !done && (
              <div className="d-flex modal-footer border-none">
                <div className="">
                  <button
                    className="btn btn-outline-secondary btn-sm text-small rounded-xx"
                    data-bs-dismiss="modal"
                    onClick={() => handleUnitTransferReset()}
                  >
                    Cancel
                  </button>
                </div>
                <div className="ms-auto">
                  <button
                    className="btn btn-primary btn-sm text-small rounded-xx"
                    onClick={() => UserOfferCreate()}
                    disabled={submit || data.txminter == ""}
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* action modal */}
      <div
        className="modal fade"
        id="modUnitTransfer"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content p-0 m-0 rounded-xd">
            <div className="modal-header border-none">
              <p className="text-normal align-middle m-0 p-0">New Transfer</p>
              <button
                type="button"
                className="btn-close text-small"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => handleUnitTransferReset()}
              ></button>
            </div>

            {/* modal content */}
            <div className="modal-body border-none">
              <div className="mb-3 d-none">
                <label className="form-label text-small">
                  Select Unit <FormNeeded />
                </label>
                <div className={""}>
                  <p className="">{unit?.meta?.name || "******"}</p>
                </div>

                <div className={""}>
                  <input
                    type="text"
                    className="form-control height-md"
                    style={{ fontSize: "0.9rem", height: "2.7rem" }}
                    value={data.txunit}
                    onChange={({ target }) => {
                      handleChange("txunit", target.value);
                    }}
                    disabled={loader || submit}
                    placeholder="123"
                  ></input>
                </div>
              </div>

              <div className="mb-3">
                <label className="form-label text-small">
                  Email <FormNeeded />
                </label>
                <input
                  type="text"
                  className="form-control height-md"
                  style={{ fontSize: "0.9rem", height: "2.7rem" }}
                  value={data.txminter}
                  onChange={({ target }) => {
                    handleChange("txminter", target.value);
                  }}
                  disabled={loader || submit}
                  placeholder="user@bharat.id"
                ></input>
              </div>

              <div className="mb-3">
                <label className="form-label text-small">
                  Number of Units <FormNeeded />
                </label>
                <input
                  type="text"
                  className="form-control height-md"
                  style={{ fontSize: "0.9rem", height: "2.7rem" }}
                  value={data.txcount}
                  onChange={({ target }) => {
                    handleChange("txcount", target.value);
                  }}
                  disabled={loader || submit || true}
                  placeholder="123"
                ></input>
              </div>

              <div className="mb-3 d-none">
                <label className="form-label text-small">
                  Rate <FormNeeded />
                </label>
                <input
                  type="text"
                  className="form-control height-md"
                  style={{ fontSize: "0.9rem", height: "2.7rem" }}
                  value={data.mxrate}
                  onChange={({ target }) => {
                    handleChange("mxrate", target.value);
                  }}
                  disabled={loader || submit}
                  placeholder="123"
                ></input>
              </div>

              <p className="text-small">{memo}</p>
            </div>

            <div className="d-flex modal-footer border-none">
              <div className="">
                <button
                  className="btn btn-outline-secondary btn-sm text-small rounded-xx"
                  data-bs-dismiss="modal"
                  onClick={() => handleUnitTransferReset()}
                >
                  Cancel
                </button>
              </div>
              <div className="ms-auto">
                <button
                  className="btn btn-primary btn-sm text-small rounded-xx"
                  // onClick={() => handleUnitTransfer()}
                  disabled={submit || data.txminter == ""}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* action modal */}
      <div
        className="modal fade"
        id="modDataDownload"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog rounded-xd">
          <div className="modal-content p-0 m-0 rounded-xd">
            <div className="modal-header border-none">
              <p className="text-normal align-middle m-0 p-0">Download Data</p>
              <button
                type="button"
                className="btn-close text-small"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            {/* modal content */}
            <div className="modal-body border-none">
              <span
                className="p-2 px-3 back-color-next text-color-wite cursor rounded-xd"
                onClick={() => handleDownload()}
              >
                {loader ? "Please Wait..." : "Download Data"}
              </span>

              <WebbDividerMedium />
            </div>

            <div className="d-flex modal-footer border-none d-none">
              <div className="">
                <button
                  className="btn btn-outline-secondary btn-sm text-small rounded-xx"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
              <div className="ms-auto">
                <button
                  className="btn btn-primary btn-sm text-small rounded-xx"
                  onClick={handleDownload}
                  disabled={data.mxcount == 0 || submit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* action modal blockchain */}
      <div
        className="modal fade"
        id="modBlockchainTransaction"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog rounded-xd">
          <div className="modal-content p-0 m-0 rounded-xd">
            <div className="modal-header border-none">
              <p className="text-normal align-middle m-0 p-0">
                View Unit Transaction On Blockchain
              </p>
              <button
                type="button"
                className="btn-close text-small"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            {/* modal content */}
            <div className="modal-body border-none">
              <p
                onClick={() =>
                  window.open(
                    unit?.webx?.link//`https://explorer.perawallet.app/asset/${unit?.webx?.item}`
                  )
                }
                className=" text-small cursor text-primary"
              >{unit?.webx?.link}</p>
              <WebbDividerMedium />
              <p className="text-small text-secondary">
                Click Above Link To View Transaction
              </p>
            </div>

            <div className="d-flex modal-footer border-none d-none">
              <div className="">
                <button
                  className="btn btn-outline-secondary btn-sm text-small rounded-xx"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
              <div className="ms-auto">
                <button
                  className="btn btn-primary btn-sm text-small rounded-xx"
                  onClick={handleDownload}
                  disabled={data.mxcount == 0 || submit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* action modal */}
      <div
        className="modal fade"
        id="modAssetDelete"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog rounded-xd">
          <div className="modal-content p-0 m-0 rounded-xd">
            <div className="modal-header border-none">
              <p className="text-normal align-middle m-0 p-0">Delete Asset</p>
              <button
                type="button"
                className="btn-close text-small"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            {/* modal content */}
            <div className="modal-body border-none"></div>

            <div className="d-flex modal-footer border-none">
              <div className="">
                <button
                  className="btn btn-outline-secondary btn-sm text-small rounded-xx"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
              <div className="ms-auto">
                <button
                  className="btn btn-primary btn-sm text-small rounded-xx"
                  onClick={() => handleUnitCreate()}
                  disabled={data.mxcount == 0 || submit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* action modal */}
      <div
        className="modal fade"
        id="modCollectData"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog rounded-xd">
          <div className="modal-content p-0 m-0 rounded-xd">
            <div className="modal-header border-none">
              <p className="text-normal align-middle m-0 p-0">Collect Data</p>
              <button
                type="button"
                className="btn-close text-small"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            {/* Modal content */}
            <div className="modal-body border-none">
              <div>
                <p className="text-bold">
                  Please Choose following data points, which you want to be
                  submitted by users before token purchase
                </p>
                <ul>
                  {/* Name Checkbox */}
                  <div>
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      checked={checkboxes.name}
                      onChange={() => handleCheckboxChange("name")}
                    />
                    <strong>Name</strong> - For personalized greetings and
                    communication
                  </div>

                  {/* Email Checkbox */}
                  <div>
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      checked={checkboxes.email}
                      onChange={() => handleCheckboxChange("email")}
                    />
                    <strong>Email</strong> - To send important updates and
                    notifications
                  </div>

                  {/* Mobile Checkbox */}
                  <div>
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      checked={checkboxes.mobile}
                      onChange={() => handleCheckboxChange("mobile")}
                    />
                    <strong>Mobile</strong> - For quick contact and verification
                  </div>

                  {/* LinkedIn Checkbox */}
                  <div>
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      checked={checkboxes.linkedin}
                      onChange={() => handleCheckboxChange("linkedin")}
                    />
                    <strong>LinkedIn</strong> - To connect and share
                    professional updates
                  </div>

                  {/* Address Checkbox */}
                  <div>
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      checked={checkboxes.address}
                      onChange={() => handleCheckboxChange("address")}
                    />
                    <strong>Address</strong> - For sending physical
                    correspondence
                  </div>

                  <div className="mt-3">
                    <p className={loader ? "text-info" : "text-success"}>
                      {memo}
                    </p>
                  </div>
                </ul>
              </div>
            </div>

            {/* Modal footer */}

            {!loader && !done && (
              <div className="d-flex modal-footer border-none">
                <div>
                  <button
                    className="btn btn-outline-secondary btn-sm text-small rounded-xx"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
                <div className="ms-auto">
                  <button
                    className="btn btn-primary btn-sm text-small rounded-xx"
                    onClick={handleSubmit}
                    disabled={
                      !checkboxes.name &&
                      !checkboxes.email &&
                      !checkboxes.mobile &&
                      !checkboxes.linkedin &&
                      !checkboxes.address
                    }
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}

            {!loader && memo === "Format Created Successfully" && (
              <div className="d-flex justify-content-end w-100 p-3 m-0">
                <button
                  className="btn btn-outline-secondary btn-sm text-small rounded-xx"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
