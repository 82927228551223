import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  updateAssetData,
  updateAssetSearchTerm,
  updateAssetSearchButton,
} from "../actions/assetAction";

import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import { useDispatch, useSelector } from "react-redux";

import { NumberFormat } from "../../services/srvc-utilities";

import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
const listFormat = require("../../data.static/data-asset-formats.json").data;

export default function AssetsSearchModule() {
  const dispatch = useDispatch();
  const assetSearchData = useSelector((state) => state.asset);

  const [filterName, setFilterName] = useState("events");

  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
  const data = useSelector((state) => state.asset);

  const navigate = useNavigate();

  const handleCheckboxChange = (key) => {
  let newData = {
    events: false,
    work: false,
    academics: false,
    membership: false,
    awards: false,
    social: false,
    all: false,
    search: "",
    searchButton: false,
    page: 1,
  };

  // Toggle the selected key
  newData[key] = !assetSearchData[key];

  // Ensure at least one button is active (default to 'events' if none)
  if (
    !newData.events &&
    !newData.work &&
    !newData.academics &&
    !newData.membership &&
    !newData.awards &&
    !newData.social &&
    !newData.all
  ) {
    newData.events = true; // Default to 'events' if none selected
  }

  // Dispatch action to update Redux state
  dispatch(updateAssetData(newData));
};



  const handleInputChange = (event) => {
    const newSearchTerm = event.target.value;
    dispatch(updateAssetSearchButton(false)); // Dispatch action to update Redux state
    dispatch(updateAssetSearchTerm(newSearchTerm)); // Dispatch action to update Redux state
  };

  const handleSearchClick = () => {
    let newData = { ...data };
    newData = { ...newData, page: 1 };
    dispatch(updateAssetData(newData)); // Dispatch action to update Redux state
    dispatch(updateAssetSearchButton(true)); // Dispatch action to update Redux state
  };

  return (
    <>
      {/* info */}
      <div className="">Search</div>
      <div className="mb-3" style={{ position: "relative" }}>
        <input
          type="text"
          className="form-control mt-3"
          value={data.search}
          onChange={handleInputChange}
        />
        <button
          type="button"
          className="btn btn-lg"
          style={{
            height: "35px",
            width: "48px",
            position: "absolute",
            right: "1px",
            top: "2px",
            padding: "0px",
            zIndex: "10000",

            background: "white",
          }}
          onClick={handleSearchClick}
        >
          <i className="bx bx-search-alt"></i>{" "}
        </button>
      </div>
      {/* format */}
      <WebbDividerSmall />
      <div className="back-color-wite p-2 px-3 rounded-xd">
        <div className="">
          <label className="form-label text-small">
            Formats <FormNeeded />
          </label>
          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Membership</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.membership}
                onChange={() => handleCheckboxChange("membership")}
              ></input>
            </div>
          </div>
          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Events</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.events}
                onChange={() => handleCheckboxChange("events")}
              ></input>
            </div>
          </div>

          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Social</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.social}
                onChange={() => handleCheckboxChange("social")}
              ></input>
            </div>
          </div>
          
          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Academics</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.academics}
                onChange={() => handleCheckboxChange("academics")}
              ></input>
            </div>
          </div>
          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Work</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.work}
                onChange={() => handleCheckboxChange("work")}
              ></input>
            </div>
          </div>
          <div className="d-flex form-check form-switch m-0 p-0 mb-2 d-none">
            <div className="">
              <p className="m-0 p-0">All</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.all}
                onChange={() => handleCheckboxChange("all")}
              ></input>
            </div>
          </div>
        </div>
      </div>

      {/* status */}
      <WebbDividerSmall />
      <div className="back-color-wite p-2 px-3 rounded-xd d-none">
        <div className="">
          <label className="form-label text-small">
            Status <FormNeeded />
          </label>

          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Live</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.live}
                onChange={() => handleCheckboxChange("live")}
              ></input>
            </div>
          </div>

          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Draft</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.draft}
                onChange={() => handleCheckboxChange("draft")}
              ></input>
            </div>
          </div>

          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Deleted</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={data.deleted}
                onChange={() => handleCheckboxChange("deleted")}
              ></input>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
