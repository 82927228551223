import React, { useState } from "react";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { GetUserForm } from "../../services/srvc-utilities";
import CertificateCanvas from "./assets-templet-certificate";
import { useParams } from "react-router-dom";

export default function CertificateModule() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
 const { id } = useParams();
  const [file, setFile] = useState(null);
  const [show, setShow] = useState(false);
  const [filePreview, setFilePreview] = useState("");

  const [base64, setBase64] = useState(""); // State for the Base64 string

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      setFilePreview(URL.createObjectURL(uploadedFile));
      // Convert file to Base64
      const reader = new FileReader();
      reader.onload = () => {
        setBase64(reader.result); // Set Base64 string
      };
      reader.readAsDataURL(uploadedFile); // Read file as Base64
    } else {
      alert("Please upload a valid file.");
    }
  };

  return (
    <div className="container">
      {!show ? (
        <div
          className="form-container"
          style={{
            margin: "50px auto",
            backgroundColor: " #ffffff",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: " 0 0 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div className="form-group my-3">
            <label htmlFor="username">Choose file:</label>
            <input
              type="file"
              className="form-control mt-2"
              id="file"
              placeholder="choose file"
              onChange={handleFileChange}
              style={{ width: "100%" }}
            />
          </div>

          <div className="d-flex mt-3">
            <div className="me-auto"></div>
            <div className="text-end">
              <button
                type="button"
                disabled={!file}
                className="btn btn-primary rounded-xx text-small"
                onClick={() => setShow(true)}
              >
                Countinue
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="form-container"
          style={{
            margin: "50px auto",
            backgroundColor: " #ffffff",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: " 0 0 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <CertificateCanvas file={filePreview} base64={base64} asset={asset} id={id} />
        </div>
      )}
    </div>
  );
}
