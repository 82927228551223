// network
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import { NumberFormat } from "../../services/srvc-utilities";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";

import NavsButtonBack from "../webx/navs-button-back";
import NavsButtonNext from "../webx/navs-button-next";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import { useDispatch, useSelector } from "react-redux";

import {
  TeamContactList,
  TeamContactSearchList,
} from "../../services/srvc-users-creators-realm";

import {
  updateNetworkData,
  updateSearchTerm,
  updateSearchButton,
} from "../actions/networkActions.js";

export default function NetworkListSmallModule(props) {
  const dispatch = useDispatch();
  const networkSearchData = useSelector((state) => state.network);

  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState();

  const [user, setUser] = useState({
    name: "******",
    mail: "******",
    item: "******",
  });
  const [search, setSearch] = useState();

  const [index, setIndex] = useState(1);
  const [items, setItems] = useState(10);

  const [curr, setCurrentIndex] = useState(1);
  const [next, setNextIndex] = useState();
  const [last, setLastIndex] = useState();

  const [count, setCount] = useState();
  const [total, setTotal] = useState();

  const [text, setText] = useState("");

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        const result = await TeamContactList({
          data: {
            team: asset.item,
            index: index,
            items: items,
          },
          service: asset?.creds?.service,
          client: asset?.creds?.client,
          secret: asset?.creds?.secret,
        });

        if (result.stat) {
          setData(result.data.list);

          const count = result.data.count;

          setTotal(count);

          setText(
            `${(index - 1) * items + 1} - ${
              index * items < count ? index * items : count
            } of ${count}`
          );
        }
        setLoader(false);
      };
      if (networkSearchData.searchTerm == "") {
        fetchData();
      }
    } else {
    }
  }, [
    props.search,
    index,
    items,
    networkSearchData.searchButton,
    networkSearchData.searchTerm,
  ]);

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        const result = await TeamContactSearchList({
          data: {
            team: asset.item,
            index: index,
            items: items,
            search: networkSearchData.searchTerm,
          },
          service: asset?.creds?.service,
          client: asset?.creds?.client,
          secret: asset?.creds?.secret,
        });

        if (result.stat) {
          setData(result.data.list);

          const count = result.data.count;

          setTotal(count);

          setText(
            `${(index - 1) * items + 1} - ${
              index * items < count ? index * items : count
            } of ${count}`
          );
        }
        setLoader(false);
      };
      if (
        networkSearchData.searchTerm !== "" &&
        networkSearchData.searchButton
      ) {
        fetchData();
      }
    } else {
    }
  }, [networkSearchData.searchButton]);

  const handleClick = async (item) => {
    var userx = data.find((x) => x.item == item);
    props.user(userx.user);
    setUser(userx.mail);

    // navigate(`/${asset.role}/ux/${item}`)
  };

  const handleInputChange = (event) => {
    const newSearchTerm = event.target.value;
    dispatch(updateSearchButton(false));
    dispatch(updateSearchTerm(newSearchTerm));
  };
  const handleSearchClick = () => {
    let newData = { ...networkSearchData };
    newData = { ...newData, page: 1 };
    dispatch(updateNetworkData(newData));
    dispatch(updateSearchButton(true));
  };

  if (loader)
    return (
      <>
        <div className="" style={{}}>
          <span className="align-middle text-lead">
            <i className="bx bxs-info-circle text-color-wait"></i>
          </span>
          <span className="ms-1 text-color-tone">Please Wait...</span>
        </div>
      </>
    );

  // if (!loader && (!data || data.length === 0))
  //   return (<>
  //     <div className='' style={{}}>
  //       <span className='align-middle text-lead'>
  //         <i className='bx bxs-info-circle text-color-tint'></i>
  //       </span>
  //       <span className='ms-1 text-color-tone'>No Contacts</span>
  //     </div>
  //   </>
  // )

  return (
    <>
      <div className="p-1 back-color-wite rounded-wd">
        {/* header */}
        <div className="">Search</div>
        <div className="mb-3" style={{ position: "relative" }}>
          <input
            type="text"
            className="form-control mt-3"
            value={networkSearchData.searchTerm}
            onChange={handleInputChange}
          />
          <button
            type="button"
            className="btn btn-lg"
            style={{
              height: "35px",
              width: "48px",
              position: "absolute",
              right: "1px",
              top: "1px",
              padding: "0px",
              zIndex: "100",
              background: "white",
            }}
            onClick={handleSearchClick}
          >
            <i className="bx bx-search-alt"></i>{" "}
          </button>
        </div>
        {/* data */}
        {data &&
          data.map((item, i) => (
            <div
              className="px-2"
              key={i}
              onClick={() => handleClick(item.item)}
            >
              <div
                className={`d-flex cursor rounded mt-1 mb-1 ${
                  user?.mail == item?.user?.mail ? "back-color-lite" : "hidark"
                }`}
              >
                <div className="d-flex ms-2" style={{ width: "90%" }}>
                  <div className="mt-1 me-2 ">
                    <span className="align-middle">
                      <Jazzicon
                        diameter={12}
                        seed={jsNumberForAddress(
                          item?.item || Date.now().toString()
                        )}
                      />
                    </span>
                  </div>
                  <div className="">
                    <p className="m-0 text-sm">
                      <span
                        className={
                          user?.name == item?.user?.name
                            ? "text-bold text-color-next align-middle"
                            : " align-middle"
                        }
                      >
                        {item.user.name}
                      </span>
                    </p>
                    <p className="m-0 text-small text-sm ">
                      <span className=" text-break" style={{}}>
                        {item.user.mail}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="ms-auto text-end text-sm">
                  <span className="m-0 align-middle">
                    <i className="bx bx-chevron-right text-lead text-color-tint d-none"></i>
                  </span>
                </div>
              </div>
              <div
                className={i < data.length - 1 ? "border-bottom d-none" : ""}
              ></div>
            </div>
          ))}
        {data.length == 0 && (
          <div className="" style={{}}>
            <span className="align-middle text-lead">
              <i className="bx bxs-info-circle text-color-tint"></i>
            </span>
            <span className="ms-1 text-color-tone">No Contacts</span>
          </div>
        )}
      </div>
    </>
  );
}
