import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  TransferDetails,
  TransfersFundDetails,
} from "../../services/srvc-transfers-funds-realm";
import { TransfersAssetDetails } from "../../services/srvc-transfers-assets-realm";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { TransfersDataDetails } from "../../services/srvc-credentials-realm";
export default function TransferUserDetail() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const { item, id } = useParams();
  const [loader, setLoader] = useState(true);
  const [currentItems, setCurrentItems] = useState();
  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
      var res = await (item == "funds"
        ? TransfersFundDetails
        : item == "assets"
        ? TransfersAssetDetails
        : TransfersDataDetails)({
        data: {
          item: id,
        },
        service: asset?.creds?.service,
        client: asset?.creds?.client,
        secret: asset?.creds?.secret,
      });
      if (res.stat) {
        console.log(res);
        setCurrentItems(res.data);
        setLoader(false);
      }
    };
    fetchData();
  }, []);

  if (loader == true) {
    return (
      <div className=" my-2 text-center px-5">
        <p>Please wait ....</p>;
      </div>
    );
  }
  if (!loader && !currentItems?.item) {
    return (
      <div className=" my-2 text-center px-5">
        <p>Transfer not found</p>;
      </div>
    );
  }
  if (item == "data") {
    return (
      <>
        {currentItems?.vars && (
          <div className=" my-2 px-5">
            <div className="bg-white my-2  p-3 border rounded">
              <p className="fw-bold">User Data</p>
              {currentItems?.vars?.map((x, index) => (
                <div
                  key={index}
                  className="d-flex gap-4 justify-content-between"
                >
                  <p>{x.value.charAt(0).toUpperCase() + x.value.slice(1)}</p>
                  <p
                    style={{
                      minWidth: "50px",
                      marginLeft: "40px",
                      wordWrap: "break-word",
                    }}
                  >
                    {x.data || "N/A"}
                  </p>
                </div>
              ))}

              <hr />

              <div className="d-flex  gap-4  justify-content-between">
                {" "}
                <p>Transfer Status</p>
                <p className="d-flex  gap-2 align-items-center gap-1">
                  <div
                    className={`${
                      currentItems?.status == 6 || currentItems?.status == "6"
                        ? "bg-success"
                        : currentItems?.status == 3 ||
                          currentItems?.status == "3" ||
                          currentItems?.status == 4 ||
                          currentItems?.status == "4" ||
                          currentItems?.status == 7 ||
                          currentItems?.status == "7" ||
                          currentItems?.status == 8 ||
                          currentItems?.status == "8" ||
                          currentItems?.status == 9 ||
                          currentItems?.status == "9"
                        ? "bg-danger"
                        : "bg-warning"
                    }`}
                    style={{
                      height: "10px",
                      width: "10px",
                      borderRadius: "50%",
                    }}
                  ></div>
                  {`${
                    currentItems?.status == 6 || currentItems?.status == "6"
                      ? "success"
                      : currentItems?.status == 3 ||
                        currentItems?.status == "3" ||
                        currentItems?.status == 4 ||
                        currentItems?.status == "4" ||
                        currentItems?.status == 7 ||
                        currentItems?.status == "7" ||
                        currentItems?.status == 8 ||
                        currentItems?.status == "8" ||
                        currentItems?.status == 9 ||
                        currentItems?.status == "9"
                      ? "failed"
                      : "pending"
                  }`}
                </p>
              </div>
              <div className="d-flex  gap-4  justify-content-between">
                {" "}
                <p>Date</p>
                <p>
                  {new Date(currentItems?.created).toLocaleString(undefined, {
                    hour12: true,
                  })}
                </p>
              </div>
              <div className="d-flex  gap-4  justify-content-between">
                {" "}
                <p>Transaction ID</p>
                <p
                  style={{
                    minWidth: "50px",
                    marginLeft: "40px",
                    wordWrap: "break-word",
                  }}
                >
                  {id}
                </p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
  return (
    <>
      {item == "funds" ? (
        <div className=" my-2 px-5">
          <div className="bg-white my-2  p-3 border rounded">
            <p className="fw-bold">Credit User</p>
            <div className="d-flex  gap-4  justify-content-between">
              <p>Name</p>
              <p
                style={{
                  minWidth: "50px",
                  marginLeft: "40px",
                  wordWrap: "break-word",
                }}
              >
                {currentItems?.credit?.name || ""}
              </p>
            </div>
            <div className="d-flex  gap-4  justify-content-between">
              <p>Email</p>
              <p
                style={{
                  minWidth: "50px",
                  marginLeft: "40px",
                  wordWrap: "break-word",
                }}
              >
                {currentItems?.credit?.mail || ""}
              </p>
            </div>{" "}
            <hr />
            <p className="fw-bold">Debit User</p>
            <div className="d-flex  gap-4  justify-content-between">
              <p>Name</p>
              <p
                style={{
                  minWidth: "50px",
                  marginLeft: "40px",
                  wordWrap: "break-word",
                }}
              >
                {currentItems?.debit?.name || ""}
              </p>
            </div>
            <div className="d-flex  gap-4  justify-content-between">
              <p>Email</p>
              <p
                style={{
                  minWidth: "50px",
                  marginLeft: "40px",
                  wordWrap: "break-word",
                }}
              >
                {currentItems?.debit?.mail || ""}
              </p>
            </div>{" "}
            <hr />
            <div className="d-flex  gap-4  justify-content-between">
              {" "}
              <p> Amount</p>
              <p
                style={{
                  minWidth: "50px",
                  marginLeft: "40px",
                  wordWrap: "break-word",
                }}
              >
                {currentItems?.amount?.number / 1000000 || "0"}{" "}
                {currentItems?.amount?.ticker.toUpperCase()}
              </p>
            </div>
            <div className="d-flex  gap-4  justify-content-between">
              {" "}
              <p>Transfer Status</p>
              <p className="d-flex  gap-2 align-items-center gap-1">
                <div
                  className={`${
                    currentItems?.status == 6 || currentItems?.status == "6"
                      ? "bg-success"
                      : currentItems?.status == 3 ||
                        currentItems?.status == "3" ||
                        currentItems?.status == 4 ||
                        currentItems?.status == "4" ||
                        currentItems?.status == 7 ||
                        currentItems?.status == "7" ||
                        currentItems?.status == 8 ||
                        currentItems?.status == "8" ||
                        currentItems?.status == 9 ||
                        currentItems?.status == "9"
                      ? "bg-danger"
                      : "bg-warning"
                  }`}
                  style={{
                    height: "10px",
                    width: "10px",
                    borderRadius: "50%",
                  }}
                ></div>
                {`${
                  currentItems?.status == 6 || currentItems?.status == "6"
                    ? "success"
                    : currentItems?.status == 3 ||
                      currentItems?.status == "3" ||
                      currentItems?.status == 4 ||
                      currentItems?.status == "4" ||
                      currentItems?.status == 7 ||
                      currentItems?.status == "7" ||
                      currentItems?.status == 8 ||
                      currentItems?.status == "8" ||
                      currentItems?.status == 9 ||
                      currentItems?.status == "9"
                    ? "failed"
                    : "pending"
                }`}
              </p>
            </div>
            <div className="d-flex  gap-4  justify-content-between">
              {" "}
              <p>Date</p>
              <p>
                {new Date(currentItems?.created).toLocaleString(undefined, {
                  hour12: true,
                })}
              </p>
            </div>
            <div className="d-flex  gap-4  justify-content-between">
              {" "}
              <p>Transaction ID</p>
              <p
                style={{
                  minWidth: "50px",
                  marginLeft: "40px",
                  wordWrap: "break-word",
                }}
              >
                {id}
              </p>
            </div>
          </div>

          <div className="d-flex  gap-4 gap-5 bg-white my-2  p-3 px-5 border rounded d-none">
            <div>
              <i className="bx bx-undo bx-md"></i> <p>Revert</p>
            </div>
            <div>
              <i className="bx bxs-time-five bx-md"></i> <p>Reschedule</p>
            </div>
          </div>
        </div>
      ) : (
        <div className=" my-2 px-5">
          <div className="bg-white my-2  p-3 border rounded">
            <p className="fw-bold">Credit User</p>
            <div className="d-flex  gap-4  justify-content-between">
              <p>Name</p>
              <p
                style={{
                  minWidth: "50px",
                  marginLeft: "40px",
                  wordWrap: "break-word",
                }}
              >
                {currentItems?.credit?.name || ""}
              </p>
            </div>
            <div className="d-flex  gap-4  justify-content-between">
              <p>Email</p>
              <p
                style={{
                  minWidth: "50px",
                  marginLeft: "40px",
                  wordWrap: "break-word",
                }}
              >
                {currentItems?.credit?.mail || ""}
              </p>
            </div>{" "}
            <hr />
            <p className="fw-bold">Debit User</p>
            <div className="d-flex  gap-4  justify-content-between">
              <p>Name</p>
              <p
                style={{
                  minWidth: "50px",
                  marginLeft: "40px",
                  wordWrap: "break-word",
                }}
              >
                {currentItems?.debit?.name || ""}
              </p>
            </div>
            <div className="d-flex  gap-4  justify-content-between">
              <p>Email</p>
              <p
                style={{
                  minWidth: "50px",
                  marginLeft: "40px",
                  wordWrap: "break-word",
                }}
              >
                {currentItems?.debit?.mail || ""}
              </p>
            </div>{" "}
            <hr />
            <div className="d-flex  gap-4  justify-content-between">
              {" "}
              <p>Amount</p>
              <p
                style={{
                  minWidth: "50px",
                  marginLeft: "40px",
                  wordWrap: "break-word",
                }}
              >
                {currentItems?.rate?.number / 1000000 || "0"}{" "}
                {currentItems?.rate?.ticker.toUpperCase()}
              </p>
            </div>
            <div className="d-flex  gap-4  justify-content-between">
              {" "}
              <p>Number of Tokens</p>
              <p
                style={{
                  minWidth: "50px",
                  marginLeft: "40px",
                  wordWrap: "break-word",
                }}
              >
                {currentItems?.size?.number || "0"}{" "}
                {currentItems?.size?.ticker.toUpperCase()}
              </p>
            </div>
            <div className="d-flex  gap-4  justify-content-between">
              {" "}
              <p>Transfer Status</p>
              <p className="d-flex  gap-2 align-items-center gap-1">
                <div
                  className={`${
                    currentItems?.status == 6 || currentItems?.status == "6"
                      ? "bg-success"
                      : currentItems?.status == 3 ||
                        currentItems?.status == "3" ||
                        currentItems?.status == 4 ||
                        currentItems?.status == "4" ||
                        currentItems?.status == 7 ||
                        currentItems?.status == "7" ||
                        currentItems?.status == 8 ||
                        currentItems?.status == "8" ||
                        currentItems?.status == 9 ||
                        currentItems?.status == "9"
                      ? "bg-danger"
                      : "bg-warning"
                  }`}
                  style={{
                    height: "10px",
                    width: "10px",
                    borderRadius: "50%",
                  }}
                ></div>
                {`${
                  currentItems?.status == 6 || currentItems?.status == "6"
                    ? "success"
                    : currentItems?.status == 3 ||
                      currentItems?.status == "3" ||
                      currentItems?.status == 4 ||
                      currentItems?.status == "4" ||
                      currentItems?.status == 7 ||
                      currentItems?.status == "7" ||
                      currentItems?.status == 8 ||
                      currentItems?.status == "8" ||
                      currentItems?.status == 9 ||
                      currentItems?.status == "9"
                    ? "failed"
                    : "pending"
                }`}
              </p>
            </div>
            <div className="d-flex  gap-4  justify-content-between">
              {" "}
              <p>Date</p>
              <p>
                {new Date(currentItems?.created).toLocaleString(undefined, {
                  hour12: true,
                })}
              </p>
            </div>
            <div className="d-flex  gap-4  justify-content-between">
              {" "}
              <p>Transaction ID</p>
              <p
                style={{
                  minWidth: "50px",
                  marginLeft: "40px",
                  wordWrap: "break-word",
                }}
              >
                {id}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
