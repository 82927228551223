// routes
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../context/authcontext'

// views - main
import Main from '../views.xz/main-xz'

// views - home
import HomeUser from '../views.home/home-user'
import HomeTeam from '../views.home/home-team'

// views - assets
import Assets from '../views.assets/assets'
import AssetsViewCreator from '../views.assets/assets-view-creator'
import AssetsCreate from '../views.assets/assets-create'

import AssetUnitDetails from '../views.assets/assets-units-details'

// views - transfers
import Transfers from '../views.transfers/transfers'

// views - transfers funds
import TransfersAccountCredit from '../views.transfers/transfers-account-credit'


// views - network
import Network from '../views.network/network'
import NetworkViewCreator from '../views.network/network-view-creator'
import NetworkMembersAdd from '../views.network/network-add'

// views - members
import MembersCreate from '../views.members/member-create'

// views - network.team
import Team from '../views.network.team/team'
import TeamMemberCreate from '../views.network.team/team-create'

// views - user
import UserDetails from '../views.user/user-details'
import TeamDetails from '../views.user/team-details'

// views - onboard
import UserOnboardName from '../views.onboard/user-onboard-name'
import UserOnboardIdentiti from '../views.onboard/user-onboard-id'
import UserOnboardMinter from '../views.onboard/user-onboard-minter'
import UserOnboardHold from '../views.onboard/user-onboard-hold'

// views - auth
import AuthMailCode from '../views.auth/auth-mail-code'
import AuthNext from '../views.auth/auth-next'
import AuthSessionX from '../views.auth/auth-session-x'
import TransfersView from '../views.transfers/transfers-view'
import NetworkAddBulkUsers from '../views.network/network-add-bulk-user'
import TransferDetailView from '../views.transfers/transfers-detail'
import TransfersFunds from '../views.transfers/transfers-funds'
import MailCreate from '../views.network/network-mail-create'
import AssetsCreateSocial from '../views.assets/assets-create-social'
import AssetsCreateAcademics from '../views.assets/assets-create-academics'
import AssetsCreateEvents from '../views.assets/assets-create-events'
import AssetsCreateWork from '../views.assets/assets-create-work'
import AssetsCollectData from '../views.assets/assets-collect-data'
import TeamAccountLogo from '../views.transfers/team-account-logo'
import TeamSocialAdd from '../views.network.team/team.social'
import CreateTempletCertificate from '../views.assets/assets-certificate'

const routes = [
  { route: "/", content: <Main />, auth: false },

  // user
  { route: "/user/home", content: <HomeUser />, auth: true },
  { route: "/user/assets", content: <Assets />, auth: true },
  { route: "/user/transfers", content: <Transfers />, auth: true },

  // home
  { route: "/team/home", content: <HomeTeam />, auth: true },

  // network
  { route: "/team/network", content: <Network />, auth: true },
  { route: "/team/ux/:id", content: <NetworkViewCreator />, auth: true },
  { route: "/team/network/mail", content: <MailCreate />, auth: true },

  // assets
  { route: "/team/assets", content: <Assets />, auth: true },
  { route: "/team/assets/:id", content: <AssetsViewCreator />, auth: true },
  { route: "/team/assets/create", content: <AssetsCreate />, auth: true },
  {
    route: "/team/assets/collectdata/:type/:id",
    content: <AssetsCollectData />,
    auth: true,
  },
  {
    route: "/team/assets/create/social",
    content: <AssetsCreateSocial />,
    auth: true,
  },
  {
    route: "/team/assets/create/academics",
    content: <AssetsCreateAcademics />,
    auth: true,
  },
  {
    route: "/team/assets/create/events",
    content: <AssetsCreateEvents />,
    auth: true,
  },
  {
    route: "/team/assets/create/work",
    content: <AssetsCreateWork />,
    auth: true,
  },

  // { route: "/team/units/:id", content: <AssetUnitDetails />, auth: true },
  {
    route: "/team/units/:type/:id",
    content: <AssetUnitDetails />,
    auth: true,
  },

  {
    route: "/team/create/template/:id",
    content: <CreateTempletCertificate />,
    auth: false,
  },

  // tokens

  // network
  { route: "/team/network", content: <Network />, auth: true },
  { route: "/team/network/add", content: <NetworkMembersAdd />, auth: true },
  {
    route: "/team/network/add/bulk",
    content: <NetworkAddBulkUsers />,
    auth: false,
  },
  { route: "/team/network/:id", content: <AssetUnitDetails />, auth: true },

  // members
  { route: "/team/members", content: <Network />, auth: true },
  // { route: '/team/members/create', content: <MembersCreate />, auth: true },
  // { route: '/team/members/revoke', content: <MembersCreate />, auth: true },

  // team
  { route: "/team/team", content: <Team />, auth: true },
  { route: "/team/members/create", content: <TeamMemberCreate />, auth: true },
  { route: "/team/social/create", content: <TeamSocialAdd />, auth: true },
  { route: "/team/members/remove", content: <TeamMemberCreate />, auth: true },

  // transfers
  { route: "/team/transfers", content: <Transfers />, auth: true },
  { route: "/team/transfers/:id", content: <TransfersView />, auth: true },

  // transfers - funds
  {
    route: "/team/transfers/account/credit",
    content: <TransfersAccountCredit />,
    auth: true,
  },
  { route: "/team/tx/:item/:id", content: <TransferDetailView />, auth: true },
  { route: "/team/funds/transfer", content: <TransfersFunds />, auth: true },
  {
    route: "/team/logo/upload",
    content: <TeamAccountLogo />,
    auth: true,
  },

  // accounts

  // user
  { route: "/user/account", content: <UserDetails />, auth: true },
  { route: "/team/account", content: <TeamDetails />, auth: true },

  // documents

  // onboard
  { route: "/user/onboard", content: <UserOnboardName />, auth: true },
  { route: "/user/onboard/id", content: <UserOnboardIdentiti />, auth: true },
  { route: "/user/onboard/minter", content: <UserOnboardMinter />, auth: true },
  { route: "/user/onboard/hold", content: <UserOnboardHold />, auth: true },

  // auth
  { route: "/auth", content: <AuthMailCode />, auth: false },
  { route: "/auth/next", content: <AuthNext />, auth: true },
  { route: "/auth/x", content: <AuthSessionX />, auth: true },
];

export default function RouteX () {
  const { user } = useAuth()
  // console.log (user)

  return (
    <Routes>
      {routes.map((item, i) =>
        item.auth ? (
          <Route
            key={i}
            path={item.route}
            element={!user ? <Navigate to='/' replace /> : item.content}
          />
        ) : (
          <Route key={i} path={item.route} element={item.content} />
        )
      )}
    </Routes>
  )
}
