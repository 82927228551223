// content
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {QRCodeCanvas} from 'qrcode.react';

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

export default function UnitCodeModule (props) {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()
  const { id ,type} = useParams()

  const [loader, setLoader] = useState(true)
  const [data, setData] = useState()

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)
        setData(props?.data || {id: id})
        // console.log(props.data)
        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [props])

console.log(props.unit)

  if (loader) return <></>

  return (
    <>
      <div className="back-color-wite text-center p-3 rounded-xd d-md-none">
        <a className='cursor' href = { props?.unit?.webx?.chain =="51" ? `https://mint.nuvo.bi/${type}/000051/${data.id}` : `https://mint.nuvo.bi/${type}/${data.id}`} target='_blank' rel="noopener">
          {/* <QRCodeCanvas value={`iam.asset.${data.id}`} size={'128'} className='w-100' includeMargin={false} /> */}
          <QRCodeCanvas value={ props?.unit?.webx?.chain =="51" ? `https://mint.nuvo.bi/${type}/000051/${data.id}` : `https://mint.nuvo.bi/${type}/${data.id}`} size={'128'} className='w-100' includeMargin={false} />

        </a>
      </div>
      
      <div className="back-color-wite text-center p-3 rounded-xd d-none d-md-block">
        <a className='cursor' href = { props?.unit?.webx?.chain =="51" ? `https://mint.nuvo.bi/${type}/000051/${data.id}` : `https://mint.nuvo.bi/${type}/${data.id}`} target='_blank' rel="noopener">
          {/* <QRCodeCanvas value={`iam.asset.${data.id}`} size={'128'} className='w-50' includeMargin={false} /> */}
          <QRCodeCanvas value={ props?.unit?.webx?.chain =="51" ? `https://mint.nuvo.bi/${type}/000051/${data.id}` : `https://mint.nuvo.bi/${type}/${data.id}`} size={'128'} className='w-50' includeMargin={false} />

        </a>
      </div>

    </>
  )
}
